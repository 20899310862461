import { memo } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import Button from '@components/Button';
import useKeypadCombination from './useKeypadCombination';

const initialCombination: Combination = [];

type KeypadProps = {
  solution: Combination;
  onSolve?: () => void;
};

const Keypad = memo(({ solution, onSolve }: KeypadProps) => {
  const [
    combination,
    error,
    addDigit,
    checkCombination,
    resetCombination,
  ] = useKeypadCombination(initialCombination, solution, onSolve);

  return (
    <div className={classNames('keypad', { 'keypad--error': error })}>
      <motion.div
        className='keypad__body'
        initial={{
          rotate: 30,
          skewX: 30,
          scale: 0.15,
          x: 322,
          y: 81,
        }}
        animate={{
          rotate: 0,
          skewX: 0,
          scale: 1,
          x: 0,
          y: 0,
        }}
        transition={{ delay: 0.6, duration: 0.6 }}
      >
        <div className='keypad__display'>
          {combination.concat(
            Array(solution.length - combination.length).fill('-')
          )}
        </div>
        <div className='keypad__keys'>
          {Array(9)
            .fill(null)
            .map((e, i) => (
              <button
                key={i}
                className='keypad__key'
                onClick={() => {
                  addDigit(i + 1);
                }}
              >
                {i + 1}
              </button>
            ))}
        </div>
      </motion.div>
      <motion.div
        className='keypad__actions'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
      >
        <Button onClick={resetCombination} outlined>
          Zurücksetzen
        </Button>
        <Button onClick={checkCombination}>Eingeben</Button>
      </motion.div>
    </div>
  );
});

export default Keypad;
