import buttonSfx from '../../sounds/key.mp3';
import errorSfx from '../../sounds/error.mp3';
import successSfx from '../../sounds/found.mp3';
import clickSfx from '../../sounds/click.mp3';
import liftSfx from '../../sounds/lift.mp3';
import snapSfx from '../../sounds/snap.mp3';
import paperSfx from '../../sounds/paper.mp3';
import lockedSfx from '../../sounds/locked.mp3';
import unlockSfx from '../../sounds/unlock.mp3';
import winSfx from '../../sounds/win.mp3';
import vasesCorrectSfx from '../../sounds/vases/correct.mp3';
import vasesSprites from '../../sounds/vases/sprites.mp3';
import vasesSpriteMap from '../../sounds/vases/sprites.json';

// workaround as typescript infers number[] from JSON instead of [number, number]
const castSpriteMap = (spriteMap: { [id: string]: number[] }) => {
  return Object.entries(spriteMap).reduce(
    (obj, [key, arr]) => ({
      ...obj,
      [key]: arr as [number, number],
    }),
    {}
  ) as SpriteMap;
};

/**
 * available sound effects
 */
export enum Sound {
  Button = 'button',
  Error = 'error',
  Success = 'success',
  Click = 'click',
  Lift = 'lift',
  Snap = 'snap',
  Paper = 'paper',
  Locked = 'locked',
  Unlock = 'unlock',
  Win = 'win',
  VasesCorrect = 'vasesCorrect',
  VasesSprites = 'vasesSprites',
}

/**
 * path to sound effect files
 */
export const SoundFile: { [K in Sound]: string | [string, SpriteMap] } = {
  [Sound.Button]: buttonSfx,
  [Sound.Error]: errorSfx,
  [Sound.Success]: successSfx,
  [Sound.Click]: clickSfx,
  [Sound.Lift]: liftSfx,
  [Sound.Snap]: snapSfx,
  [Sound.Paper]: paperSfx,
  [Sound.Locked]: lockedSfx,
  [Sound.Unlock]: unlockSfx,
  [Sound.Win]: winSfx,
  [Sound.VasesCorrect]: vasesCorrectSfx,
  [Sound.VasesSprites]: [vasesSprites, castSpriteMap(vasesSpriteMap.sprite)],
};
