import { memo } from 'react';
import { WindowContent } from '@components/Window';
import Keypad from './Keypad';
import Notes from './Notes';
import SafeClosed from '@img/safe/closed.svg';

const solution: Combination = [1, 2, 7, 3];

type SafeGameUnsolvedProps = {
  onSolve?: () => void;
};

const SafeGameUnsolved = memo(({ onSolve }: SafeGameUnsolvedProps) => {
  return (
    <WindowContent
      heading='Sie haben einen geheimen Safe gefunden.'
      intro={
        <p>
          Um den Safe öffnen zu können, müssen Sie eine 4-stellige
          Zahlenkombination eingeben. <br />
          Hinweise auf den Zahlencode sind im Wohnzimmer verteilt.
        </p>
      }
    >
      <div className='safe'>
        <Notes />
        <Keypad solution={solution} onSolve={onSolve} />
        <img className='safe__safe' src={SafeClosed} alt='verschloßener Safe' />
      </div>
    </WindowContent>
  );
});

export default SafeGameUnsolved;
