import { createContext, useContext } from 'react';
import { createNanoEvents } from 'nanoevents';

// singleton bus
const bus = createNanoEvents();

// create context
const UnityBusContext = createContext(bus);

// global provider to provide bus
type UnityBusContextProviderProps = {
  children: React.ReactNode;
};
export const UnityBusContextProvider = ({
  children,
}: UnityBusContextProviderProps) => (
  <UnityBusContext.Provider value={bus}>{children}</UnityBusContext.Provider>
);

// allow components to listen/emit to bus
const useUnityBus = () => useContext(UnityBusContext);

export default useUnityBus;
