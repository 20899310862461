import { memo } from 'react';
import { useToggleMuted } from '@lib/state';
import IconButton from '@components/IconButton';
import { VolumeOff, VolumeUp } from '@components/Icon';

const MuteButton = memo(() => {
  const [muted, toggleMuted] = useToggleMuted();

  return (
    <IconButton
      onClick={toggleMuted}
      Icon={muted ? VolumeOff : VolumeUp}
      floatOnTop
    />
  );
});

export default MuteButton;
