import { memo } from 'react';

const Letter = memo(() => (
  <svg
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M16.9 16c-.5 0-.9.4-.9.9V23H3.8a.9.9 0 01-.9-.9V3.8c0-.5.4-.9.9-.9H22c.5 0 1 .4 1 .9V16h-6.2z'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      transform='matrix(.7283 0 0 .81944 2.6 1.4)'
    />
    <path
      d='M97.4 90.4l-7 7'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      transform='matrix(.7283 0 0 .81944 -51.6 -59.6)'
    />
    <path
      d='M29.2 48.4l.8-.9c.5-.4 1.3-.4 1.8 0 .5.5 1.3.5 1.7 0 .5-.4 1.3-.4 1.8 0 .5.5 1.3.5 1.7 0 .5-.4 1.3-.4 1.8 0 .5.5 1.2.5 1.7 0l.9-.8'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      transform='matrix(.7283 0 0 .81944 -13.7 -29.1)'
    />
    <path
      d='M29.2 80.5l.8-.9c.5-.5 1.3-.5 1.8 0s1.3.5 1.7 0'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      transform='matrix(.7283 0 0 .81944 -13.7 -51.8)'
    />
  </svg>
));

export default Letter;
