import { memo } from 'react';
import { WindowContent } from '@components/Window';
import { useElapsed } from '@lib/state';
import Logos from '@components/Logos';
import Button from '@components/Button';
import formatDuration from 'format-duration';
import WonImg from '@img/won.jpg';

type GreenhouseWonProps = {
  animate: boolean;
};

const GreenhouseWon = memo(({ animate }: GreenhouseWonProps) => {
  const elapsed = useElapsed();

  return (
    <WindowContent heading='Im Gewächshaus' animate={animate}>
      <div className='won container container--full'>
        <div className='won__columns'>
          <div className='won__content'>
            <div className='won__intro'>
              <p>
                Sie betreten das Gewächshaus und genießen für einen Moment den
                Duft von frischen Äpfeln. Dann sehen Sie sich um und entdecken
                auch sofort die Gießkanne.
                <br /> Sie gießen das Apfelbäumchen. Ihre Gastgeberin wird Ihnen
                dankbar sein!
              </p>

              <p>
                Zur Belohnung für Ihre Bemühungen pflücken Sie sich einen Apfel
                und beißen herzhaft hinein.
              </p>

              <p>
                Sie haben alles geschafft. Wir hoffen, es hat Ihnen ein wenig Spaß
                gemacht und wünschen Ihnen nach dieser kleinen Pause noch einen
                erfolgreichen Tag!
              </p>
            </div>

            <p className='text text--large'>
              Ihre Spieldauer: {formatDuration(elapsed)} min
            </p>

            <div className='won__outro'>
              <p>
                Neugierde geweckt? Um mehr über unsere Produkte zu erfahren,
                besuchen Sie uns auf unserer Webseite.
              </p>
            </div>

            <Button
              className='won__action'
              href='https://link.b-ms.de/3HulO2I'
            >
              Mehr Informationen
            </Button>
          </div>
          <img src={WonImg} alt='Apfelbäume' className='won__visual' />
        </div>
        <Logos className='won__logos' />
      </div>
    </WindowContent>
  );
});

export default GreenhouseWon;
