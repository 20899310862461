import { memo } from 'react';
import { WindowContent } from '@components/Window';
import { Item, ItemMeta, useAddUsed } from '@lib/state';
import Solved from '@components/Solved';
import Button from '@components/Button';
import GreenhouseOpen from '@img/greenhouse/open.svg';

type GreenhouseSolvedProps = {
  correctKey: Item;
  animate: boolean;
};

const GreenhouseSolved = memo(
  ({ correctKey, animate }: GreenhouseSolvedProps) => {
    let src = ItemMeta?.[correctKey]?.imageSrc;
    src = Array.isArray(src) ? src[0] : src;
    const addUsed = useAddUsed();

    return (
      <WindowContent
        heading='Der Schlüssel passt.'
        intro={
          <p>
            Der Schlüssel gleitet ins Schloss – und lässt sich drehen.
            <br />
            Das Schloss öffnet sich.
          </p>
        }
        animate={animate}
      >
        <Solved
          animate={animate}
          imageSrc={GreenhouseOpen}
          itemSrc={src}
          action={
            <Button onClick={() => addUsed(Item.GreenhouseDoor)}>
              Gewächshaus betreten
            </Button>
          }
        />
      </WindowContent>
    );
  }
);

export default GreenhouseSolved;
