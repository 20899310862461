import { memo } from 'react';
import { useGoToExplore, Item } from '@lib/state';
import Overlay, { OverlayVariant } from '@components/Overlay';
import FlyerOverview from './FlyerOverview';
import FlyerDetail from './FlyerDetail';
import { AnimateSharedLayout } from 'framer-motion';

type FlyerProps = {
  flyer?: Item;
};
const Flyer = memo(({ flyer }: FlyerProps) => {
  const close = useGoToExplore();

  return (
    <Overlay
      layerClassName='flyer'
      variant={OverlayVariant.Large}
      handleClose={close}
    >
      <AnimateSharedLayout>
        {flyer ? <FlyerDetail flyer={flyer} /> : <FlyerOverview />}
      </AnimateSharedLayout>
    </Overlay>
  );
});

export default Flyer;
