import {
  ErrorBoundary as ErrorBoundaryComponent,
  FallbackProps,
} from 'react-error-boundary';
import Button from '@components/Button';

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div className='error'>
      <h2 className='error__title'>Es ist ein Fehler aufgetreten</h2>
      <p>Bitte laden Sie die Seite erneut.</p>
      <Button onClick={() => window.location.reload()}>Seite neu laden</Button>
    </div>
  );
};

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <ErrorBoundaryComponent FallbackComponent={Fallback}>
      {children}
    </ErrorBoundaryComponent>
  );
};

export default ErrorBoundary;
