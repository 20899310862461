import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState, RecoilState } from 'recoil';
import persistEffect from '@lib/state/persistEffect';
import { Callout, View } from '@lib/entities';
import { useView } from './view';

/**
 * Callout state
 */
export const calloutsState: RecoilState<Callout[]> = atom({
  key: 'callouts',
  default: [] as Callout[],
  effects_UNSTABLE: [persistEffect()],
});

/**
 * add Callout to callouts array
 * @type {Function}
 */
export const useAddCallout = () => {
  const setCallout = useSetRecoilState(calloutsState);

  return useCallback(
    (callouts: Callout | Callout[]) => {
      setCallout((prevCallouts) => prevCallouts.concat(callouts));
    },
    [setCallout]
  );
};

/**
 * dismiss current callout, optionally limited to specific views
 * @type {Function}
 */
export const useDismissCallout = (dismissInViews?: View | View[]) => {
  const setCallout = useSetRecoilState(calloutsState);
  const currentView = useView();

  return useCallback(() => {
    if (
      !dismissInViews ||
      (Array.isArray(dismissInViews)
        ? dismissInViews.includes(currentView)
        : dismissInViews === currentView)
    ) {
      setCallout((prevCallouts) => prevCallouts.slice(1));
    }
  }, [setCallout, currentView, dismissInViews]);
};

/**
 * current callout + dismiss
 */
export const useCallout = (dismissInViews?: View | View[]) => {
  const callouts = useRecoilValue(calloutsState);
  const dismiss = useDismissCallout(dismissInViews);

  return [callouts.length === 0 ? undefined : callouts[0], dismiss] as const;
};
