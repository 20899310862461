import { memo, useRef } from 'react';
import Window from '@components/Window';
import { useFound, useUsed, useWasUsed, ItemGroup, Item } from '@lib/state';
import GreenhouseSolved from './GreenhouseSolved';
import GreenhouseUnsolved from './GreenhouseUnsolved';
import GreenhouseWon from './GreenhouseWon';

const Greenhouse = memo(() => {
  const found = useFound();
  const keysFound = ItemGroup.Keys.filter((key) => found.includes(key));
  const used = useUsed();
  const keysUsed = ItemGroup.Keys.filter((key) => used.includes(key));
  const doorUsed = useWasUsed(Item.GreenhouseDoor);
  const open = keysUsed.length === ItemGroup.Keys.length;
  const doorUsedRef = useRef(open && doorUsed);
  const openRef = useRef(open);
  const wonShouldAnimate = !doorUsedRef.current;
  const solvedShouldAnimate = !openRef.current;

  return (
    <Window>
      {open && doorUsed ? (
        <GreenhouseWon animate={wonShouldAnimate} />
      ) : open ? (
        <GreenhouseSolved
          animate={solvedShouldAnimate}
          correctKey={keysFound[keysFound.length - 1]}
        />
      ) : (
        <GreenhouseUnsolved keysFound={keysFound} keysUsed={keysUsed} />
      )}
    </Window>
  );
});

export default Greenhouse;
