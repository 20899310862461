import { memo, useRef } from 'react';
import Window from '@components/Window';
import { useItem, Item } from '@lib/state';
import SafeGameUnsolved from './SafeGameUnsolved';
import SafeGameSolved from './SafeGameSolved';

const SafeGame = memo(() => {
  const [solved, solve] = useItem(Item.SafeKey);
  const solvedRef = useRef(solved);
  const solvedShouldAnimate = !solvedRef.current;

  return (
    <Window>
      {solved ? (
        <SafeGameSolved animate={solvedShouldAnimate} />
      ) : (
        <SafeGameUnsolved onSolve={solve} />
      )}
    </Window>
  );
});

export default SafeGame;
