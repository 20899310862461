import { useCallback } from 'react';
import {
  atom,
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  RecoilState,
} from 'recoil';
import persistEffect from '@lib/state/persistEffect';
import { Item } from '@lib/entities';

/**
 * state of found items
 */
export const foundState: RecoilState<Item[]> = atom({
  key: 'found',
  default: [] as Item[],
  effects_UNSTABLE: [persistEffect()],
});

/**
 * returns all found items
 */
export const useFound = () => useRecoilValue(foundState);

/**
 * add Item to found array
 * @type {Function}
 */
export const useAddFound = () => {
  const setFound = useSetRecoilState(foundState);

  return useCallback(
    (item: Item) => {
      setFound((prevFound) =>
        prevFound.includes(item) ? prevFound : prevFound.concat(item)
      );
    },
    [setFound]
  );
};

/**
 * checks if items have been found

 * @type {boolean}
 */
export const useWasFound = (items: Item | Item[]) => {
  const found = useRecoilValue(foundState);

  if (Array.isArray(items)) {
    return items.filter((item) => found.includes(item)).length;
  }

  return found.includes(items);
};

/**
 * tracks single item
 */
export const useItem = (item: Item) => {
  const [found, setFound] = useRecoilState(foundState);
  const itemFound = found.includes(item);
  const findItem = useCallback(
    () =>
      setFound((prevFound) =>
        prevFound.includes(item) ? prevFound : prevFound.concat(item)
      ),
    [setFound, item]
  );

  return [itemFound, findItem] as const;
};
