import { useCallback } from 'react';
import { useAddFound, useSetView, ItemMeta } from '@lib/state';
import { Item } from '@lib/entities';

/**
 * process clicks on items in Stage
 */
const useHandleObjectClick = () => {
  const addFound = useAddFound();
  const setView = useSetView();

  return useCallback(
    (item: Item) => {
      // item might be found for the first time
      addFound(item);

      // some items launch views
      const launchView = ItemMeta?.[item]?.triggersView;
      if (launchView) {
        setView(launchView);
      }
    },
    [addFound, setView]
  );
};

export default useHandleObjectClick;
