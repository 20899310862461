import { memo } from 'react';

const Flyer = memo(() => (
  <svg
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    strokeLinecap='round'
    strokeLinejoin='round'
    stroke='currentColor'
    strokeWidth='1.7'
  >
    <path
      d='M23 21.2l-1-1.7v-4.3c0-1.6-1.8-3-3.8-4.6m1.6 6.4l-2.6-2.4h-.5c-.7 0-1.3.5-1.3 1.2v.4l2.8 2.8v1.2a6 6 0 001 2.5M1 2.7l1 1.8v4.3c0 1.6 1.8 3 3.8 4.6M4.2 7l2.6 2.4h.5c.7 0 1.3-.5 1.3-1.2v-.4L5.7 5V3.8a6 6 0 00-1-2.5'
      fill='none'
    />
    <path
      d='M5.8 8.3V19c0 .5.4.9.9.9h9m2.5-4.2V5c0-.5-.4-.9-.9-.9H7.7'
      fill='none'
    />
  </svg>
));

export default Flyer;
