import { memo } from 'react';
import { WindowContent } from '@components/Window';
import Solved from '@components/Solved';
import SafeOpen from '@img/safe/open.svg';
import SafeKey from '@img/keys/safe.svg';

type SafeGameSolvedProps = {
  animate: boolean;
};

const SafeGameSolved = memo(({ animate }: SafeGameSolvedProps) => {
  return (
    <WindowContent
      heading='Der Safe öffnet sich.'
      intro={
        <p>
          Die eingegebene Zahlenkombination war korrekt. <br />
          Im Safe finden Sie einen Schlüssel. <br />
          Ob dieser ins Schloss des Gewächshauses passt?
        </p>
      }
      animate={animate}
    >
      <Solved imageSrc={SafeOpen} itemSrc={SafeKey} animate={animate} />
    </WindowContent>
  );
});

export default SafeGameSolved;
