import { memo, useMemo } from 'react';
import { useFound, ItemGroup, usePreviousView, ItemMeta } from '@lib/state';
import { useMeasure } from 'react-use';
import { motion } from 'framer-motion';
import Heading from '@components/Heading';
import IconButton from '@components/IconButton';
import { ChevronRight, ChevronLeft } from '@components/Icon';
import { useIndexPagination } from '@lib/hooks';
import FlyerItem from './FlyerItem';

const perPage = 4;

const FlyerOverview = memo(() => {
  // combine found flyers + placeholders
  const found = useFound();
  const flyersFound = useMemo(
    () => found.filter((item) => ItemGroup.Flyers.includes(item)),
    [found]
  );
  const flyers = useMemo(
    () =>
      flyersFound.concat(
        Array(ItemGroup.Flyers.length - flyersFound.length).fill(null)
      ),
    [flyersFound]
  );

  // measure item width
  const [sliderRef, { width: sliderWidth }] = useMeasure<HTMLDivElement>();
  const itemWidth = (sliderWidth > 0 ? sliderWidth : 959) / perPage; // hacky!! magic number to fix slider animation from detail view

  // find previously viewed flyer index
  const previousView = usePreviousView();
  const previousIndex = flyers.findIndex((item) => {
    return ItemMeta?.[item]?.triggersView === previousView;
  });

  // set up pagination
  const {
    currentIndex,
    isFirst,
    isLast,
    goNext,
    goPrevious,
  } = useIndexPagination(flyers, previousIndex, perPage);
  const offset = currentIndex * itemWidth * -1;

  return (
    <>
      <Heading>Gefundene Flyer</Heading>
      <p>
        Sie haben {flyersFound.length} von {ItemGroup.Flyers.length} Flyern
        gefunden.
      </p>
      <div className='flyer__overview'>
        <IconButton
          Icon={ChevronLeft}
          className='flyer__prev'
          disabled={isFirst}
          onClick={goPrevious}
        />
        <div className='flyer__viewport' ref={sliderRef}>
          <motion.div
            className='flyer__slider'
            animate={{ x: offset }}
            initial={false}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            {flyers.map((flyer, flyerIndex) => {
              if (!flyer) {
                return <FlyerItem key={flyerIndex} />;
              }

              return <FlyerItem key={flyer} flyer={flyer} />;
            })}
          </motion.div>
        </div>
        <IconButton
          Icon={ChevronRight}
          className='flyer__next'
          disabled={isLast}
          onClick={goNext}
        />
      </div>
    </>
  );
});

export default FlyerOverview;
