import { memo, useCallback } from 'react';
import { WindowContent } from '@components/Window';
import Button from '@components/Button';
import { useSound, Sound } from '@lib/sfx';
import useSoundCombination from './useSoundCombination';
import Vases from './Vases';

const solution = ['1', '2', '3', '4'];

type VaseGameUnsolvedProps = {
  onSolve?: () => void;
};

const VaseGameUnsolved = memo(({ onSolve }: VaseGameUnsolvedProps) => {
  const [handleNote] = useSoundCombination(
    Sound.VasesSprites,
    solution,
    onSolve
  );
  const [playMelody, { isPlaying }] = useSound(Sound.VasesCorrect);
  const handlePlay = useCallback(() => {
    playMelody();
  }, [playMelody]);

  return (
    <WindowContent
      heading='Sie haben ein Rätsel gefunden.'
      intro={<p>Ob sich in einer der Vasen ein Schlüssel verbirgt?</p>}
    >
      <div className='vases'>
        <Button
          className='vases__play'
          onClick={isPlaying ? undefined : handlePlay}
          disabled={isPlaying}
        >
          {isPlaying ? 'Melodie spielt ab…' : 'Melodie abspielen'}
        </Button>
        <Vases handleNote={handleNote} />
      </div>
    </WindowContent>
  );
});

export default VaseGameUnsolved;
