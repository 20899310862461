import { memo } from 'react';
import Stage from '@components/Stage';
import GlobalUI from '@components/GlobalUI';
import Router from '@components/Router';
import { useStateEffects } from '@lib/state';

const App = memo(() => {
  useStateEffects();

  return (
    <div className='app'>
      <Stage />
      <GlobalUI />
      <Router />
    </div>
  );
});

export default App;
