import { memo } from 'react';
import UI from '@components/UI';
import MuteButton from './MuteButton';
import ExitButton from './ExitButton';
import LetterButton from './LetterButton';
import GreenhouseButton from './GreenhouseButton';
import FlyerButton from './FlyerButton';
import GlobalHints from './GlobalHints';

const GlobalUI = memo(() => {
  return (
    <UI
      topRight={<ExitButton />}
      bottomLeft={
        <>
          <LetterButton />
          <GreenhouseButton />
          <FlyerButton />
        </>
      }
      bottomRight={
        <>
          <MuteButton />
          <GlobalHints />
        </>
      }
    />
  );
});

export default GlobalUI;
