import { memo } from 'react';
import classNames from 'classnames';
import LogoImnovid from '@img/logos/Logo-Imnovid.svg';
import LogoInrebic from '@img/logos/INREBIC_Logo_dt_RGB.png';
import LogoEmpliciti from '@img/logos/Empliciti_NALogo_R_RGB.svg';
import LogoReblozyl from '@img/logos/Reblozyl-Logo.png';
import LogoAbecma from '@img/logos/Abecma_Logo_1400x456px.png';
import LogoOnureg from '@img/logos/Onureg_Logo_RGB_deutsch.png';
import LogoBreyanzi from '@img/logos/Breyanzi_logo_20220309.png';

type LogosProps = {
  className?: string;
};

const Logos = memo(({ className }: LogosProps) => {
  return (
    <div className={classNames('logos', className)}>
      <div className='logos__wrap'>
        <div className='logos__item'>
          <img src={LogoImnovid} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoEmpliciti} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoAbecma} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoBreyanzi} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoReblozyl} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoOnureg} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoInrebic} alt='' className='logos__logo' />
        </div>
      </div>
    </div>
  );
});

export default Logos;
