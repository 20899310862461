import { memo } from 'react';
import classNames from 'classnames';

type ButtonProps = {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  outlined?: boolean;
};

const Button = memo(
  ({
    className,
    children,
    onClick,
    disabled,
    loading,
    outlined,
    href,
  }: ButtonProps) => {
    if (href) {
      return (
        <a
          className={classNames('button', className, {
            'button--loading': loading,
            'button--outlined': outlined,
          })}
          href={href}
        >
          {children}
          {loading ? (
            <>
              <span className='dot1'>.</span>
              <span className='dot2'>.</span>
              <span className='dot3'>.</span>
            </>
          ) : null}
        </a>
      );
    }

    return (
      <button
        className={classNames('button', className, {
          'button--loading': loading,
          'button--outlined': outlined,
        })}
        disabled={loading || disabled}
        onClick={onClick}
      >
        {children}
        {loading ? (
          <>
            <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </>
        ) : null}
      </button>
    );
  }
);

export default Button;
