import { memo } from 'react';
import IconButton, {
  LabelPosition,
  IconButtonVariant,
} from '@components/IconButton';
import { Close } from '@components/Icon';
import { View, useGoToView } from '@lib/state';

const ExitButton = memo(() => {
  const exit = useGoToView(View.Exit);

  return (
    <IconButton
      label='Wohnzimmer verlassen'
      Icon={Close}
      labelPosition={LabelPosition.Left}
      variant={IconButtonVariant.Secondary}
      onClick={exit}
    />
  );
});

export default ExitButton;
