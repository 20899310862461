import { memo } from 'react';
import IconButton from '@components/IconButton';
import { Letter } from '@components/Icon';
import { CalloutBubble } from '@components/Bubble';
import { Callout, useWasFound, Item, View, useGoToView } from '@lib/state';

const LetterButton = memo(() => {
  const openLetter = useGoToView(View.Letter);
  const wasFound = useWasFound(Item.Letter);

  return wasFound ? (
    <CalloutBubble
      content='Die gefundene Nachricht können Sie hier jederzeit erneut aufrufen.'
      callout={Callout.Letter}
      dismissInViews={View.Explore}
    >
      <IconButton Icon={Letter} onClick={openLetter} />
    </CalloutBubble>
  ) : null;
});

export default LetterButton;
