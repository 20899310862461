import { memo } from 'react';
import { motion, MotionProps } from 'framer-motion';
import classNames from 'classnames';
import { useKey } from 'react-use';
import UI from '@components/UI';
import { ChevronRight } from '@components/Icon';
import WindowContent from './WindowContent';
import { MotionVariant } from '@lib/motion';
import { useGoToExplore } from '@lib/state';

type WindowProps = {
  children?: React.ReactNode;
  className?: string;
  initial?: MotionProps['initial'];
  animate?: MotionProps['animate'];
  exit?: MotionProps['exit'];
  heading?: React.ReactNode;
  intro?: React.ReactNode;
};

/**
 * Full Window
 */
const Window = memo(
  ({
    children,
    className,
    initial = 'initial',
    animate = 'animate',
    exit = 'exit',
    heading,
    intro,
  }: WindowProps) => {
    const goBack = useGoToExplore();
    useKey('Escape', goBack);

    return (
      <motion.div
        className={classNames('window', className)}
        variants={MotionVariant.GrowSoft}
        initial={initial}
        animate={animate}
        exit={exit}
      >
        <UI
          topRight={
            <button className='window__exit' onClick={goBack}>
              <div className='window__exit-label'>Zurück ins Wohnzimmer</div>
              <div className='window__exit-icon'>
                <ChevronRight />
              </div>
            </button>
          }
        />
        {intro || heading ? (
          <WindowContent heading={heading} intro={intro}>
            {children}
          </WindowContent>
        ) : (
          children
        )}
      </motion.div>
    );
  }
);

export default Window;
