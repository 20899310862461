import { memo } from 'react';

const Greenhouse = memo(() => (
  <svg
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='1.7'
    stroke='currentColor'
  >
    <path
      d='M13.4 15.6v6.9s0 0 0 0c0 .2.2.4.5.4 0 0 0 0 0 0h6.6s0 0 0 0c.3 0 .5-.2.5-.4 0 0 0 0 0 0v-6.9'
      fill='none'
    />
    <path
      d='M11.5 17.2l5.7-4.7 5.7 4.7M11.5 8.2C11.5 5.4 9.2 3 6.3 3a5.3 5.3 0 00-5.2 5.2c0 2.4 1.5 4.4 3.8 5v1.2L4 15v.4l.8.8v1L4 18v.4l.8.7v1.5L6 21.8h.4l1.2-1.2v-7.4a5.2 5.2 0 003.8-5z'
      fill='none'
    />
    <path
      d='M4.2 7.4a2.1 2.1 0 004.2 0c0-1.2-1-2.1-2-2.1-1.2 0-2.2 1-2.2 2z'
      fill='none'
    />
    <path
      d='M6.6 4.9c1-2.8 4-4.4 6.8-3.6 2.3.7 3.8 2.8 3.8 5.2v6.2'
      fill='none'
    />
  </svg>
));

export default Greenhouse;
