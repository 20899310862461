import { useRef, memo } from 'react';
import classNames from 'classnames';
import { WindowContent } from '@components/Window';
import Button from '@components/Button';
import DragItem from './DragItem';
import useDragCombination from './useDragCombination';
import { useHints, View } from '@lib/state';

const initialCombination: Combination = [4, 2, 1, 3];
const solution: Combination = [1, 2, 3, 4];

type CouchGameUnsolvedProps = {
  onSolve?: () => void;
};

const CouchGameUnsolved = memo(({ onSolve }: CouchGameUnsolvedProps) => {
  const constraintsRef = useRef<HTMLDivElement>(null);

  const [
    combination,
    error,
    updateOrder,
    checkCombination,
    itemRef,
  ] = useDragCombination(initialCombination, solution, onSolve);
  const [requestedHints] = useHints(View.CouchGame);

  return (
    <WindowContent
      heading='Sie haben ein Rätsel gefunden.'
      intro={
        <p>
          Der Schlüssel könnte dort verloren gegangen sein, wo Ihr Gastgeber
          zuletzt auf dem Sofa saß. 
          <br /> Untersuchen Sie die Spuren, die er hinterlassen hat und bringen
          Sie die Bilder in die richtige Reihenfolge.
        </p>
      }
    >
      <div
        className={classNames('couch container', {
          'couch--error': error,
          'couch--hints': requestedHints >= 3,
        })}
      >
        <div className='couch__drag container__grow'>
          <div className='couch__timeline'></div>
          <div className='couch__items' ref={constraintsRef}>
            {combination.map((combinationItem, i) => (
              <DragItem
                key={combinationItem}
                combinationItem={combinationItem}
                ref={i === 0 ? itemRef : null}
                constraintsRef={constraintsRef}
                updateOrder={updateOrder}
                shiftImg={i % 2 !== 0}
              />
            ))}
          </div>
        </div>
        <div className='couch__actions container__actions'>
          <Button onClick={checkCombination}>Überprüfen</Button>
        </div>
      </div>
    </WindowContent>
  );
});

export default CouchGameUnsolved;
