import { memo, useState } from 'react';
import classNames from 'classnames';
import Button from '@components/Button';
import { WindowContent } from '@components/Window';
import Overlay, { OverlayVariant } from '@components/Overlay';
import { Item, ItemGroup, ItemMeta, useAddUsed } from '@lib/state';
import { Close } from '@components/Icon';
import GreenhouseClosed from '@img/greenhouse/closed.svg';

type GreenhouseUnsolvedProps = {
  keysFound: Item[];
  keysUsed: Item[];
};

const GreenhouseUnsolved = memo(
  ({ keysFound, keysUsed }: GreenhouseUnsolvedProps) => {
    const addUsed = useAddUsed();
    const [popupVisible, setPopupVisible] = useState(false);

    return (
      <WindowContent
        heading='Gewächshaus'
        intro={
          <p>
            Sie stehen nun vor dem Gewächshaus, aber noch ist es verschlossen.
            Probieren Sie aus, ob einer der gefundenen Schlüssel passt.
          </p>
        }
      >
        <>
          <div className='greenhouse'>
            <img
              src={GreenhouseClosed}
              alt='verschlossenes Gewächshaus'
              className='greenhouse__image'
            />
            <div className='greenhouse__keys'>
              {ItemGroup.Keys.map((key) => {
                const found = keysFound.includes(key);
                const used = keysUsed.includes(key);

                let src = ItemMeta?.[key]?.imageSrc;
                src = Array.isArray(src) ? (found ? src[0] : src[1]) : src;

                return (
                  <div
                    className={classNames('greenhouse__key', {
                      'greenhouse__key--used': used,
                      'greenhouse__key--found': found,
                    })}
                    key={key}
                  >
                    <img src={src} alt={key} />
                    <Close />
                    {!used && (
                      <Button
                        className='greenhouse__button'
                        disabled={!found}
                        onClick={
                          !used
                            ? () => {
                                addUsed(key);
                                setPopupVisible(true);
                              }
                            : undefined
                        }
                      >
                        Ausprobieren
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {popupVisible && (
            <Overlay
              variant={OverlayVariant.Small}
              handleClose={() => setPopupVisible(false)}
            >
              Sie stecken den Schlüssel ins Schloss. Er passt hinein. Lässt sich
              aber nicht drehen.
            </Overlay>
          )}
        </>
      </WindowContent>
    );
  }
);

export default GreenhouseUnsolved;
