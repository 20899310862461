import { memo } from 'react';
import { RecoilRoot } from 'recoil';
import App from '@components/App';
import Legal from '@components/Legal';
import ErrorBoundary from '@components/ErrorBoundary';
import { UnityBusContextProvider } from '@lib/unity';

const Root = memo(() => {
  // overwrite App in dev mode
  let MaybeDevApp = App;
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_DEV_BUILD === 'true'
  ) {
    MaybeDevApp = require('@components/App/App.dev').default;
  }

  return (
    <RecoilRoot>
      <div className='wrapper'>
        <ErrorBoundary>
          <UnityBusContextProvider>
            <MaybeDevApp />
            <Legal />
          </UnityBusContextProvider>
        </ErrorBoundary>
      </div>
    </RecoilRoot>
  );
});

export default Root;
