import { memo } from 'react';
import { motion } from 'framer-motion';
import { useGoToExplore } from '@lib/state';
import Button from '@components/Button';

const itemVariant = {
  initial: { opacity: 0, x: -250, scale: 0.5, rotate: -30 },
  animate: {
    rotate: 0,
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      delay: 0.5,
      duration: 1,
      type: 'spring',
    },
  },
};

type SolvedProps = {
  imageSrc: string;
  itemSrc?: string;
  action?: React.ReactNode;
  animate?: boolean;
};

const Solved = memo(({ imageSrc, itemSrc, action, animate }: SolvedProps) => {
  const goBack = useGoToExplore();

  return (
    <div className='solved container'>
      <div className='solved__images container__grow'>
        <img src={imageSrc} alt='' className='solved__image' />
        {itemSrc && (
          <motion.img
            variants={itemVariant}
            initial={animate ? 'initial' : false}
            animate={animate ? 'animate' : false}
            src={itemSrc}
            alt=''
            className='solved__item'
          />
        )}
      </div>
      <div className='solved__actions container__actions'>
        {action ? (
          action
        ) : (
          <Button onClick={goBack}>Zurück ins Wohnzimmer</Button>
        )}
      </div>
    </div>
  );
});

export default Solved;
