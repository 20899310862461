import { AtomEffect, DefaultValue } from 'recoil';

const persistEffect = <T>(storageKey: string = 'recoil-persist') => {
  const persistAtom: AtomEffect<T> = ({ onSet, node, trigger, setSelf }) => {
    if (trigger === 'get') {
      const value = get(node.key);

      if (typeof value !== 'undefined') {
        setSelf(value);
      }
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        remove(node.key);
      } else {
        set(node.key, newValue);
      }
    });
  };

  /**
   * return current state object from storage
   * @type {object}
   */
  const getState = () => {
    const savedState = window.localStorage.getItem(storageKey);
    return savedState !== null ? JSON.parse(savedState) : {};
  };

  /**
   * write object to storage
   * @type {[type]}
   */
  const setState = (nextState: any) => {
    window.localStorage.setItem(storageKey, JSON.stringify(nextState));
  };

  /**
   * get item
   * @type {T}
   */
  const get = (nodeKey: string) => {
    const state = getState();
    return state[nodeKey] as T | undefined;
  };

  /**
   * set item
   * @type {void}
   */
  const set = (nodeKey: string, value: T) => {
    const state = getState();
    setState({ ...state, [nodeKey]: value });
  };

  /**
   * remove item
   * @type {void}
   */
  const remove = (nodeKey: string) => {
    const state = getState();
    const { [nodeKey]: _, ...nextState } = state;
    setState(nextState);
  };

  return persistAtom;
};

export default persistEffect;
