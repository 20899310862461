import { useLayoutEffect, useRef, useCallback } from 'react';
import { UnityInstance, useUnityBus } from '@lib/unity';
import { useErrorHandler } from 'react-error-boundary';
import { Emitter } from 'nanoevents';

/**
 * instantiates unity
 * @param {string}            buildUrl URL to build.json
 * @param {Function}          onInit callback to run after load. receives unityInstance. (WARNING: should be memoized, otherwise unity restarts on every render cycle)
 */
export default function useUnity(
  unityConfig: Record<string, string>,
  onLoad?: (unityInstance: UnityInstance, unityBus: Emitter) => void,
  onProgress?: (progress: number) => void
) {
  const stageRef = useRef<HTMLCanvasElement>(null);
  const unityInstanceRef = useRef<UnityInstance>();
  const handleError = useErrorHandler();
  const unityBus = useUnityBus();

  const onLoadWithBus = useCallback(
    (unityInstance: UnityInstance) => {
      if (onLoad) {
        onLoad(unityInstance, unityBus);
      }
    },
    [onLoad, unityBus]
  );

  useLayoutEffect(() => {
    const stageEl = stageRef.current;

    if (stageEl !== null) {
      // load unity component
      const instance = new UnityInstance(
        stageEl,
        unityConfig,
        onLoadWithBus,
        onProgress,
        handleError
      );

      // store reference
      unityInstanceRef.current = instance;
    }

    return () => {
      // cleanup on teardown
      if (unityInstanceRef.current) {
        unityBus.events = {};
        unityInstanceRef.current.quit();
        unityInstanceRef.current = undefined;
      }
    };
  }, [unityConfig, onProgress, handleError, onLoadWithBus, unityBus]);

  return stageRef;
}
