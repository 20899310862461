import { useCallback } from 'react';
import { atom, RecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useIdle, useInterval } from 'react-use';
import { useView, View, elapsedWithoutProgressState } from '@lib/state';
import persistEffect from '@lib/state/persistEffect';

/**
 * Elapsed time
 */

export const elapsedState: RecoilState<number> = atom({
  key: 'elapsed',
  default: 0,
  effects_UNSTABLE: [persistEffect()],
});

/**
 * returns elapsed time
 */
export const useElapsed = () => useRecoilValue(elapsedState);

/**
 * updates elapsed time
 */
export const useElapsedLoop = (
  pauseInViews: View[] = [],
  pauseIfTrue: boolean = false,
  timePrecision: number = 1e3,
  idleDelay: number = 30e3
) => {
  // determine if loop should currently run
  const isIdle = useIdle(idleDelay);
  const view = useView();
  const shouldRun = !isIdle && !pauseInViews.includes(view) && !pauseIfTrue;

  // handle tick
  const setElapsed = useSetRecoilState(elapsedState);
  const setElapsedWithoutProgress = useSetRecoilState(
    elapsedWithoutProgressState
  );
  const onTick = useCallback(() => {
    setElapsed((elapsed) => elapsed + timePrecision);
    setElapsedWithoutProgress((elapsed) => elapsed + timePrecision);
  }, [setElapsed, setElapsedWithoutProgress, timePrecision]);

  // run loop
  useInterval(onTick, shouldRun ? timePrecision : null);
};
