import { useRef, memo } from 'react';
import Window from '@components/Window';
import { useItem, Item } from '@lib/state';
import CouchGameUnsolved from './CouchGameUnsolved';
import CouchGameSolved from './CouchGameSolved';

const CouchGame = memo(() => {
  const [solved, solve] = useItem(Item.CouchKey);
  const solvedRef = useRef(solved);
  const solvedShouldAnimate = !solvedRef.current;

  return (
    <Window>
      {solved ? (
        <CouchGameSolved animate={solvedShouldAnimate} />
      ) : (
        <CouchGameUnsolved onSolve={solve} />
      )}
    </Window>
  );
});

export default CouchGame;
