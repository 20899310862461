import { useRef } from 'react';
import { useMouseHovered, useMeasure, useHoverDirty } from 'react-use';

const useHoverZoom = (scale = 3) => {
  // track mouse position & hover state on thumb
  const thumbRef = useRef(null);
  const {
    elH: thumbHeight,
    elW: thumbWidth,
    elX: hoverX,
    elY: hoverY,
  } = useMouseHovered(thumbRef, {
    bound: true,
    whenHovered: true,
  });
  const isHovering = useHoverDirty(thumbRef);

  // track size of full
  const [fullRef, { width: fullWidth, height: fullHeight }] = useMeasure<
    HTMLDivElement
  >();

  // clamp scale
  scale = Math.max(scale, fullWidth / thumbWidth, fullHeight / thumbHeight);

  let zoom;
  let area;
  if (
    isHovering &&
    thumbWidth > 0 &&
    fullWidth > 0 &&
    thumbHeight > 0 &&
    fullHeight > 0
  ) {
    const areaWidth = fullWidth / scale;
    const areaHeight = fullHeight / scale;

    area = {
      width: areaWidth,
      height: areaHeight,
      left: Math.min(
        Math.max(hoverX - areaWidth / 2, 0),
        thumbWidth - areaWidth
      ),
      top: Math.min(
        Math.max(hoverY - areaHeight / 2, 0),
        thumbHeight - areaHeight
      ),
    };

    zoom = {
      width: scale * thumbWidth,
      height: scale * thumbHeight,
      left: scale * area.left * -1,
      top: scale * area.top * -1,
    };
  }

  return [
    { thumbRef, area },
    { ref: fullRef, zoom },
  ] as const;
};

export default useHoverZoom;
