import { useCallback } from 'react';
import { atom, useRecoilValue, useRecoilState, RecoilState } from 'recoil';
import persistEffect from '@lib/state/persistEffect';
import { useSound, Sound } from '@lib/sfx';

/**
 * muted state
 */
export const mutedState: RecoilState<boolean> = atom({
  key: 'muted',
  default: false,
  effects_UNSTABLE: [persistEffect()],
});

/**
 * returns currently muted value
 */
export const useMuted = () => useRecoilValue(mutedState);

/**
 * toggle muted
 */
export const useToggleMuted = () => {
  const [clickSfx] = useSound(Sound.Click);
  const [muted, setMuted] = useRecoilState(mutedState);
  const toggleMuted = useCallback(
    () =>
      setMuted((muted) => {
        if (muted) {
          clickSfx({ forceSoundEnabled: muted });
        }

        return !muted;
      }),
    [setMuted, clickSfx]
  );

  return [muted, toggleMuted] as const;
};
