import { memo } from 'react';
import classNames from 'classnames';

export enum HeadingSize {
  H1 = 'h1',
  H2 = 'h2',
}

type HeadingProps = {
  children?: React.ReactNode;
  className?: string;
  size?: HeadingSize;
};

const Heading = memo(
  ({ children, size = HeadingSize.H1, className }: HeadingProps) => {
    let Component: keyof JSX.IntrinsicElements;

    switch (size) {
      case HeadingSize.H2:
        Component = 'h2';
        break;

      default:
        Component = 'h1';
        break;
    }

    return (
      <Component
        className={classNames('heading', `heading--${size}`, className)}
      >
        {children}
      </Component>
    );
  }
);

export default Heading;
