import { memo } from 'react';
import { useGoToExplore, useElapsed } from '@lib/state';
import Overlay, { OverlayVariant } from '@components/Overlay';
import Button from '@components/Button';
import Logos from '@components/Logos';
import Heading, { HeadingSize } from '@components/Heading';
import LogoBMS from '@img/logos/bms.svg';
import { Help, Close } from '@components/Icon';
import { useLoaded } from '@lib/state';

type WelcomeProps = { isLoading?: boolean };

const Welcome = memo(({ isLoading = false }: WelcomeProps) => {
  const close = useGoToExplore();
  const elapsed = useElapsed();
  const loaded = useLoaded();

  return (
    <Overlay
      initial={false}
      variant={OverlayVariant.Small}
      layerClassName='welcome'
      handleClose={isLoading ? undefined : close}
    >
      <img src={LogoBMS} alt='Bristol Myers Squibb' className='welcome__logo' />
      <Heading size={HeadingSize.H2}>
        Herzlich willkommen im digitalen BMS Escape Room!
      </Heading>
      <p className='text text--small'>
        Sie betreten gleich einen 3D-Raum, in dem Sie einige Rätsel finden und
        lösen müssen.
      </p>
      <ul className='text text--small'>
        <li>Für eine optimale Spielerfahrung, betreten Sie den Raum am besten von einem Desktop-PC aus.</li>
        <li>Im Raum können Sie mit der Maus navigieren.</li>
        <li>
          Wenn Sie hervorgehobene Objekte entdecken, klicken Sie diese an, um zu
          interagieren.
        </li>

        <li>
          Hilfe finden Sie hinter dem Fragezeichen <Help /> rechts unten.
        </li>

        <li>
          Sie können das Spiel jederzeit über den Schließen-Button <Close />{' '}
          oben rechts verlassen.
        </li>
      </ul>

      <p className='text'>
        Viel Vergnügen wünscht Ihnen das BMS-Hämatologie-Team
      </p>

      <div className='welcome__enter'>
        {isLoading ? (
          <Button loading>Raum lädt ({Math.floor(loaded * 100)} %)</Button>
        ) : (
          <Button onClick={close}>
            {elapsed > 0 ? 'Spiel fortsetzen' : 'Raum betreten'}
          </Button>
        )}
      </div>

      <Logos className='welcome__logos' />
    </Overlay>
  );
});

export default Welcome;
