import { memo, useRef } from 'react';
import Window from '@components/Window';
import { useItem, Item } from '@lib/state';
import VaseGameUnsolved from './VaseGameUnsolved';
import VaseGameSolved from './VaseGameSolved';

const VaseGame = memo(() => {
  const [solved, solve] = useItem(Item.VaseKey);
  const solvedRef = useRef(solved);
  const solvedShouldAnimate = !solvedRef.current;

  return (
    <Window>
      {solved ? (
        <VaseGameSolved animate={solvedShouldAnimate} />
      ) : (
        <VaseGameUnsolved onSolve={solve} />
      )}
    </Window>
  );
});

export default VaseGame;
