import { memo, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

export enum LabelPosition {
  Right = 'right',
  Left = 'left',
}

export enum IconButtonVariant {
  Secondary = 'secondary',
}

const labelVariants = {
  initial: {
    opacity: 0,
    width: 0,
  },
  hover: {
    opacity: 1,
    width: 'auto',
  },
};
const labelTransition = { duration: 0.4 };

type IconButtonProps = {
  className?: string;
  Icon: React.ElementType<any>;
  labelPosition?: LabelPosition;
  label?: string;
  badge?: string;
  variant?: IconButtonVariant;
  floatOnTop?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const IconButton = memo(
  ({
    className,
    Icon,
    labelPosition,
    label,
    badge,
    variant,
    floatOnTop,
    disabled,
    onClick,
  }: IconButtonProps) => {
    const [isHovered, setHovered] = useState(false);

    return (
      <motion.button
        className={classNames('icon-button', className, {
          'icon-button--label-reversed': labelPosition === LabelPosition.Left,
          [`icon-button--${variant}`]: variant,
          'icon-button--on-top': floatOnTop,
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        disabled={disabled}
      >
        <div className='icon-button__icon'>
          <Icon />
        </div>
        {label ? (
          <motion.div
            className='icon-button__label'
            initial='initial'
            animate={isHovered ? 'hover' : 'initial'}
            variants={labelVariants}
            transition={labelTransition}
          >
            <div className='icon-button__label-inner'>{label}</div>
          </motion.div>
        ) : null}
        {badge ? <div className='icon-button__badge'>{badge}</div> : null}
      </motion.button>
    );
  }
);

export default IconButton;
