import { memo } from 'react';

const Help = memo(() => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25.7 25.7'
    fill='currentColor'
  >
    <g transform='translate(-4.833 -4.839)'>
      <path d='M17.7 30.6c-7.1 0-12.9-5.8-12.9-12.9 0-3.4 1.3-6.7 3.8-9.1 2.4-2.4 5.7-3.8 9.1-3.8 7.1 0 12.9 5.8 12.9 12.9 0 7.1-5.8 12.9-12.9 12.9zm0-23.8C14.8 6.8 12.1 8 10 10c-2.1 2.1-3.2 4.8-3.2 7.7 0 6 4.9 10.9 10.9 10.9s10.9-4.9 10.9-10.9S23.7 6.8 17.7 6.8z' />
      <path d='M17.7 21.9c-.6 0-1-.4-1-1v-.4c0-1.3.7-2.4 1.9-2.9.8-.4 1.3-1.2 1.3-2.1 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 .6-.4 1-1 1s-1-.4-1-1c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2c0 1.7-1 3.2-2.5 3.9-.4.2-.7.6-.7 1.1v.4c0 .6-.4 1-1 1z' />
      <path d='M17.7 22.1c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z' />
    </g>
  </svg>
));

export default Help;
