import { memo } from 'react';
import { useFound, Item } from '@lib/state';
import NoteSevenEmpty from '@img/notes/seven-empty.svg';
import NoteSeven from '@img/notes/seven.svg';
import NoteLowestEmpty from '@img/notes/lowest-empty.svg';
import NoteLowest from '@img/notes/lowest.svg';
import NoteSumEmpty from '@img/notes/sum-empty.svg';
import NoteSum from '@img/notes/sum.svg';
import Note23Empty from '@img/notes/2-3-empty.svg';
import Note23 from '@img/notes/2-3.svg';

const Notes = memo(() => {
  const found = useFound();

  return (
    <div className='safe__notes'>
      <img
        src={
          found.includes(Item.FlyerReblozyl) ? NoteSum : NoteSumEmpty
        }
        alt={Item.FlyerReblozyl}
        className='safe__note'
      />
      <img
        src={found.includes(Item.FlyerOnu) ? NoteSeven : NoteSevenEmpty}
        alt={Item.FlyerOnu}
        className='safe__note'
      />
      <img
        src={
          found.includes(Item.FlyerEmpliciti) ? Note23 : Note23Empty
        }
        alt={Item.FlyerEmpliciti}
        className='safe__note'
      />
      <img
        src={found.includes(Item.FlyerImnovid) ? NoteLowest : NoteLowestEmpty}
        alt={Item.FlyerImnovid}
        className='safe__note'
      />
    </div>
  );
});

export default Notes;
