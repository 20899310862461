import { memo } from 'react';
import { Item, ItemMeta, useGoToView, View } from '@lib/state';
import Heading from '@components/Heading';
import Button from '@components/Button';
import {
  HoverZoomThumb,
  HoverZoomFull,
  useHoverZoom,
} from '@components/HoverZoom';
import { motion } from 'framer-motion';

type FlyerDetailProps = {
  flyer: Item;
};

const FlyerDetail = memo(({ flyer }: FlyerDetailProps) => {
  const showOverview = useGoToView(View.FlyerOverview);

  const [thumbProps, fullProps] = useHoverZoom();

  const src = ItemMeta?.[flyer]?.imageSrc;
  const thumbSrc = Array.isArray(src) ? src[0] : src;
  const fullSrc = Array.isArray(src) ? src[1] : src;

  return (
    <div className='flyer__detail'>
      <div className='flyer__detail-body container container--block'>
        <Heading>Sie haben einen Flyer gefunden.</Heading>
        <p>
          Auf den Post-its können Sie wichtige Hinweise für die Suche nach dem Schlüssel finden.
        </p>
        {thumbSrc && (
          <motion.div layoutId={flyer}>
            <HoverZoomThumb
              className='flyer__thumb container__grow'
              src={thumbSrc}
              {...thumbProps}
            />
          </motion.div>
        )}
        <Button onClick={showOverview}>Alle Flyer anzeigen</Button>
      </div>
      {fullSrc && (
          <HoverZoomFull
            className='flyer__full container__grow'
            src={fullSrc}
            {...fullProps}
          >
            &larr; Fahren Sie mit der Maus über den Flyer, um ihn zu vergrößern.
          </HoverZoomFull>
        )}
    </div>
  );
});

export default FlyerDetail;
