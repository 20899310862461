import { memo } from 'react';
import { WindowContent } from '@components/Window';
import Solved from '@components/Solved';
import CouchSolved from '@img/couch/4.svg';
import CouchKey from '@img/keys/couch.svg';

type CouchGameSolvedProps = {
  animate: boolean;
};

const CouchGameSolved = memo(({ animate }: CouchGameSolvedProps) => {
  return (
    <WindowContent
      heading='Hier saß Ihr Gastgeber zuletzt.'
      intro={
        <p>
          Die Reihenfolge der Bilder war richtig. Hinter dem Sofakissen finden
          Sie einen Schlüssel. Ob Sie mit diesem das Gewächshaus öffnen können?
        </p>
      }
      animate={animate}
    >
      <Solved imageSrc={CouchSolved} itemSrc={CouchKey} animate={animate} />
    </WindowContent>
  );
});

export default CouchGameSolved;
