import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState, RecoilState } from 'recoil';
import { Item } from '@lib/entities';
import persistEffect from '@lib/state/persistEffect';

/**
 * Track items usage
 */

export const usedState: RecoilState<Item[]> = atom({
  key: 'used',
  default: [] as Item[],
  effects_UNSTABLE: [persistEffect()],
});

/**
 * returns all used items
 */
export const useUsed = () => useRecoilValue(usedState);

/**
 * checks if item(s) have been used

 * @type {boolean}
 */
export const useWasUsed = (items: Item | Item[]) => {
  const used = useRecoilValue(usedState);

  if (Array.isArray(items)) {
    return items.filter((item) => used.includes(item)).length;
  }

  return used.includes(items);
};

/**
 * add Item to used array
 * @type {Function}
 */
export const useAddUsed = () => {
  const setFound = useSetRecoilState(usedState);

  return useCallback(
    (item: Item) => {
      setFound((prevUsed) =>
        prevUsed.includes(item) ? prevUsed : prevUsed.concat(item)
      );
    },
    [setFound]
  );
};
