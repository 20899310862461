import { useCallback } from 'react';
import {
  atom,
  selector,
  useSetRecoilState,
  useRecoilValue,
  RecoilState,
} from 'recoil';
import { View, initialView, exploreView } from '@lib/entities';

const MAX_HISTORY = 10;

/**
 * current view state
 */
export const viewHistoryState: RecoilState<View[]> = atom({
  key: 'viewHistory',
  default: [initialView] as View[],
});

export const currentViewState = selector({
  key: 'currentView',
  get: ({ get }) => {
    const history = get(viewHistoryState);
    return history[0];
  },
});

export const previousViewState = selector({
  key: 'previousView',
  get: ({ get }) => {
    const history = get(viewHistoryState);
    return history[1];
  },
});

/**
 * set current view
 */
export const useSetView = () => {
  const _setView = useSetRecoilState(viewHistoryState);
  const setView = useCallback(
    (view) =>
      _setView((history) => {
        if (history[0] && history[0] === view) {
          return history;
        }

        return [view, ...history].slice(0, MAX_HISTORY);
      }),
    [_setView]
  );

  return setView;
};

/**
 * returns current view
 */
export const useView = () => useRecoilValue(currentViewState);

/**
 * returns previous view
 */
export const usePreviousView = () => useRecoilValue(previousViewState);

/**
 * navigate to specific view
 */
export const useGoToView = (view: View) => {
  const setView = useSetView();
  const goToView = useCallback(() => setView(view), [setView, view]);
  return goToView;
};

/**
 * navigate to explore view
 */
export const useGoToExplore = () => useGoToView(exploreView);
