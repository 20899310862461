import { useRef, useCallback, memo } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence, MotionProps } from 'framer-motion';
import { Close } from '@components/Icon';
import { useClickAway } from 'react-use';
import { MotionVariant } from '@lib/motion';

type BubbleProps = {
  children: React.ReactNode;
  className?: string;
  floatOnTop?: boolean;
  content: React.ReactNode;
  handleClose?: () => void;
  visible?: boolean;
  initial?: MotionProps['initial'];
  animate?: MotionProps['animate'];
  exit?: MotionProps['exit'];
  closeOnChildClick?: boolean;
};

const Bubble = memo(
  ({
    children,
    className,
    floatOnTop,
    content,
    handleClose,
    visible,
    initial = 'initial',
    animate = 'animate',
    exit = 'exit',
    closeOnChildClick = false,
  }: BubbleProps) => {
    const ref = useRef(null);
    const closeIfVisible = useCallback(() => {
      if (handleClose && visible) {
        handleClose();
      }
    }, [handleClose, visible]);

    // hide current bubble on click away
    useClickAway(ref, closeIfVisible);

    return (
      <div
        className={classNames('bubble-wrap', {
          'bubble-wrap--on-top': floatOnTop,
        })}
        ref={closeOnChildClick ? null : ref}
      >
        {children}
        <AnimatePresence>
          {visible && (
            <motion.div
              className={classNames(
                'bubble',
                {
                  'bubble--on-top': floatOnTop,
                },
                className
              )}
              variants={MotionVariant.FadeUp}
              initial={initial}
              animate={animate}
              exit={exit}
              key='bubble'
              ref={closeOnChildClick ? ref : null}
            >
              {handleClose ? (
                <button className='bubble__close' onClick={handleClose}>
                  <Close />
                </button>
              ) : null}
              <div className='bubble__content'>{content}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default Bubble;
