import { useState, useCallback } from 'react';
import { useUpdateEffect } from 'react-use';
import { useSound, Sound } from '@lib/sfx';

const useCombination = (
  initialCombination: Combination,
  solution: Combination,
  onSolve?: () => void
) => {
  const [combination, setCombination] = useState<Combination>(
    initialCombination
  );
  const [error, setError] = useState(false);
  const [errorSfx] = useSound(Sound.Error);

  /**
   * check current combination
   */
  const check = useCallback(() => {
    if (
      combination.length === solution.length &&
      combination.every((val, index) => val === solution[index])
    ) {
      setError(false);

      if (onSolve) {
        onSolve();
      }
    } else {
      setError(true);
    }
  }, [combination, onSolve, solution]);

  /**
   * reset combination
   */
  const reset = useCallback(() => {
    setError(false);
    setCombination([]);
  }, []);

  // play error sound if error is set
  useUpdateEffect(() => {
    if (error) {
      errorSfx();
    }
  }, [error]);

  // reset error on combination change
  useUpdateEffect(() => {
    setError(false);
  }, [combination]);

  return [combination, error, setCombination, check, reset] as const;
};

export default useCombination;
