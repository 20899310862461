import { memo } from 'react';

type VasesProps = {
  handleNote: (id: string) => () => void;
};

const Vases = memo(({ handleNote }: VasesProps) => {
  return (
    <div className='vases__vases'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        strokeLinejoin='round'
        strokeMiterlimit='2'
        clipRule='evenodd'
        viewBox='0 0 531 304'
        className='vases__image'
      >
        <path
          fill='#e6e6e6'
          fillRule='nonzero'
          d='M530.7 216l-30 18.6-41.9-22.9 29.9-15.6 42 20v-.1z'
        ></path>
        <g fillRule='nonzero' className='vases__vase'>
          <path
            fill='#f4e4cb'
            d='M475 141.4l25.8-15 26.2 15-26 15-26-15z'
          ></path>
          <path
            fill='#e5d1ac'
            d='M478.6 141.4l22.2-13 22.7 13-22.5 13-22.4-13z'
          ></path>
          <path fill='#f4e4cb' d='M501 231.8v-75.3l26-15v75.3l-26 15z'></path>
          <path fill='#e5d1ac' d='M475 141.4l26 15v75.4l-26-15v-75.4z'></path>
          <path fill='#ccb58f' d='M500.8 128.4l.2 26 22.5-13-22.7-13z'></path>
          <path
            fill='none'
            className='vases__outline'
            d='M500.8 124.6l-27.3 16v77l27.5 16 27.5-16v-77l-27.7-16z'
          ></path>
        </g>
        <ellipse
          cx='334.9'
          cy='225.8'
          fill='#e6e6e6'
          rx='28.7'
          ry='10.2'
        ></ellipse>
        <g className='vases__vase'>
          <ellipse
            cx='339.6'
            cy='225.1'
            fill='#e5d1ac'
            rx='18.7'
            ry='7.9'
          ></ellipse>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M384 150.6v.2c0 26-25.7 74.3-25.7 74.3h-37.4s-11.6-22.7-19.2-44.9c-3.7-9.5-6-19.5-6.7-29.6V148l.1-1.3a24.3 24.3 0 0119.6-23.4h49.6s19.6 5.5 19.8 27.3h-.1z'
          ></path>
          <path
            fill='#f4e4cb'
            fillRule='nonzero'
            d='M384 150.6c-1.7 12.3-21 22-44.5 22-4.2 0-8.4-.3-12.6-1-17.4-2.8-30.4-11-31.9-21v-.4c0-1.2 0-2.4.2-3.5a24.3 24.3 0 0119.6-23.5h49.6s19.5 5.6 19.7 27.4h-.1z'
          ></path>
          <path
            fill='#ccb58f'
            fillRule='nonzero'
            d='M327 171.6c-7.9-.6-24.7-1-25.3 8.6-3.7-9.5-6-19.5-6.7-29.6 1.5 10 14.5 18.3 32 21z'
          ></path>
          <path
            fill='#f4e4cb'
            fillRule='nonzero'
            d='M365.5 118.7v1c0 .8-.3 1.6-.6 2.4-1.4 3.8-5.2 7-10.4 9.2a41.2 41.2 0 01-29.9 0c-5.2-2.2-9-5.4-10.4-9.2l-.4-1.6-.1-.5v-1.3c0-8.5 11.6-15.4 26-15.4s25.8 6.9 25.8 15.4z'
          ></path>
          <path
            fill='#c68554'
            fillRule='nonzero'
            d='M313.6 119.6v.1-.1z'
          ></path>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M365.5 119.6c0 .8-.3 1.6-.6 2.4-1.4 3.8-5.2 7-10.4 9.3a41.2 41.2 0 01-29.9 0c-5.2-2.2-9-5.5-10.4-9.3l-.4-1.5.3 1.9 1.4 8.8c4 5.6 13.2 9.6 24 9.6s20.2-4 24-9.6l1.5-8.7.4-2.7.1-.2z'
          ></path>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M360.7 117.2c0 1-.2 2-.7 3-2.4 5-10.5 8.6-20.1 8.6-9.6 0-17.7-3.6-20-8.6-.5-1-.8-2-.8-3 0-6.4 9.3-11.6 20.8-11.6s20.8 5.2 20.8 11.6z'
          ></path>
          <path
            fill='#ccb58f'
            fillRule='nonzero'
            d='M360 120.2c-2.4 5-10.5 8.6-20 8.6s-17.8-3.6-20.2-8.6c2.4-5 10.5-8.6 20.1-8.6 9.6 0 17.7 3.7 20.1 8.6z'
          ></path>
          <clipPath id='a'>
            <path d='M427.6 96.4l-39.6-49-51.5-42-62.9 38 20.5 30.8 29.7 41.7c-1.7 1-3 2.5-4 4.3 2.4 5 10.5 8.6 20.1 8.6 9.6 0 17.7-3.6 20.1-8.6l-.3-.5 45.7-2.5 22.2-20.8z'></path>
          </clipPath>
          <g fillRule='nonzero' clipPath='url(#a)'>
            <path
              fill='#60b200'
              d='M336 137.3s2.2-46.7-7.5-67.2-15.3-36.3-18.2-39.2a53 53 0 0132 41.9c1.8 17.4 2 63.5 2.2 66.8.2 3.3-8.4-2.3-8.4-2.3h-.1z'
            ></path>
            <path
              fill='#60b200'
              d='M333.4 133s4.6-47.2-16-65.8c-20.6-18.6-35-18-35-18s13.7 8.1 20.6 19.6c6.9 11.5 24.7 43 24.4 57.8-.3 14.8 6 6.3 6 6.3v.1zm13.1 7.7s-1.7-44 9.1-51.8c10.8-7.8 29-11.3 41.4-3.1 12.4 8.2 9.6 22 9.6 22s.4-6.5-9.6-13.2c-10-6.7-24-4.5-32.1-.8-7.5 3.5-11.7 26.4-12.8 36.6-1.1 10.2-5.6 10.3-5.6 10.3z'
            ></path>
            <path
              fill='#60b200'
              d='M352.8 137.1s-9.6-58.8-4-76.2c5.6-17.4 23.7-25.2 23.7-25.2s-10.6 16.2-12.7 28.7c-2.1 12.5-7.5 64.3-5.9 68.7 1.6 4.4-1 4-1 4h-.1z'
            ></path>
            <path
              fill='#83cc05'
              d='M340 128.1s1.3-29.5 0-35.6c-1.3-6.1-7.7-10-17.1-9.8-4 2.3-1.5 8.8 0 14.6 1.5 5.8 6.6 25.4 7.8 35.4 9 2.1 9.4-4.6 9.4-4.6h-.1zm5.7 7.3s-1.5-48.4 1.3-58c2.8-9.6 5.8-9.2 9.6-8.2s9 2.1 3.8 8.6-8.6 27.5-8.4 36c.2 8.5-.2 23.3-.2 23.3l-6.1-1.7z'
            ></path>
          </g>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M397.9 84.5c-11.6-7.6-28-5.5-39.2.7V84c.7-1.9 1.6-3.6 2.8-5.2 2.2-2.7 3-4.9 2.4-6.7a5.3 5.3 0 00-3.3-3.2l.7-4.2c2-12.1 12.4-28 12.5-28.2l2.7-4.1-4.6 2c-.7.3-18.7 8.2-24.5 26-1.4 5.4-2 10.9-1.8 16.4v.2c-.5 2.2-.9 4.4-1 6.6l-.8-11a54.6 54.6 0 00-33-43l-7-3 5.4 5.4a84 84 0 018.1 16.7c2.7 6.1 6 13.7 9.9 22 1.6 3.7 2.9 7.5 3.7 11.4l-2.1-.5c-2.3-6-5.8-11.2-10.4-15.6-20.7-18.8-35.4-18.4-36-18.4l-5 .3 4.2 2.5a66 66 0 0120 19.2c3.8 6.3 13 22.2 19 36.8-5.3 3-8.3 7.2-8.6 11.7h-.3l.5 2.6.3 1.5c-11 3-18.7 13-18.9 24.4v1.4l-.1 2.2v.4a100 100 0 006.7 30 394.3 394.3 0 0019.2 44.8c.4 5.2 9 9 20.2 9s19.7-3.8 20.1-9c2.4-4.5 25.8-49.4 25.8-74.6v-.3c0-18.8-14-26-19-28l.4-2.5v-1.3c0-4.7-3-9-8.3-12.2 2.7-7.9 5.2-10.5 6.9-11.3 8.4-3.9 21.6-5.4 30.7.7 9.1 6.1 9 11.7 9 12l3 .3c0-.2 2.7-15-10.3-23.7z'
          ></path>
        </g>
        <ellipse cx='396' cy='255.5' fill='#e6e6e6' rx='37.1' ry='11'></ellipse>
        <g className='vases__vase' onClick={handleNote('4')}>
          <ellipse
            cx='400.8'
            cy='5.8'
            fill='#a26330'
            rx='7.4'
            ry='4.3'
          ></ellipse>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M400.8 2.3v7.1c-3.6 0-6.4-1.6-6.4-3.5s2.9-3.6 6.4-3.6z'
          ></path>
          <path
            fill='#8c5125'
            fillRule='nonzero'
            d='M407.1 5.8c0 2-2.8 3.6-6.3 3.6V2.3c3.5 0 6.3 1.6 6.3 3.5z'
          ></path>
          <path
            fill='#84644f'
            fillRule='nonzero'
            d='M441.8 191v-.2.2zm0 0v-.2.2z'
          ></path>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M442.4 190.9v8.2c-1.4 28.8-16.4 50.1-19.6 54.9l-1 1.3c-2.7 2.7-6 4.7-9.7 5.8A39.3 39.3 0 01401 263h-.3c-5.6 0-16.9-2-21.9-9-3.2-4.8-18.1-26.1-19.5-55v-8c0-2.2.3-4.4.5-6.7l.8-4c1.5-5.7 4.6-13.8 8.4-22.9 10-24 24.5-55.5 24.5-71V5.8c.5 2.7 3 4.2 7.3 4.2s7-1.5 7.4-4.2v80.6c0 21.4 27.5 72.9 32.9 93.9l.8 4 .5 6.6z'
          ></path>
          <path
            fill='#84644f'
            fillRule='nonzero'
            d='M359.8 191h.1v-.2l-.1.2z'
          ></path>
          <path
            fill='#84644f'
            fillRule='nonzero'
            d='M359.8 191h.1v-.2l-.1.2z'
          ></path>
          <path
            fill='#a26330'
            fillRule='nonzero'
            d='M359.7 190.8v.1l.4 1.5c3.5 11.4 20.3 20.1 40.5 20.1h.4c20.2 0 37-8.7 40.5-20.1l.4-1.5v-.1l.4-.8-.5-5.6-.8-4-.4 1a18.8 18.8 0 01-2.9 4.3 29 29 0 01-6 5.2 56.4 56.4 0 01-30.9 8.3h-.1c-11 .3-21.7-2.6-31-8.3a29 29 0 01-6-5.2l-1.2-1.6-1.6-2.8-.4-1-.8 4c-.3 2-.4 3.8-.5 5.7.2 0 .5.8.5.8zm82.6-.8v1-1z'
          ></path>
          <path
            fill='#a26330'
            fillRule='nonzero'
            d='M400.9 218.1h-.2c-21.8 0-40.1-10.2-41.5-23v4c2 12.4 20 22.2 41.4 22.2h.4c21.4 0 39.3-9.8 41.3-22.2v-.1l.1-4c-1.4 13-19.6 23.1-41.5 23.1z'
          ></path>
          <path
            fill='#b58f76'
            fillOpacity='0.1'
            fillRule='nonzero'
            d='M427.9 167.3c5.4 16.9 9 33.4 5 49.7-4 16.3-10.7 6.8-10.5-7.5a118 118 0 00-10.2-44.7c-5.9-14.1 11-11.8 15.7 2.4v.1z'
          ></path>
          <path
            fill='#7a431e'
            fillOpacity='0.3'
            fillRule='nonzero'
            d='M421.8 255.3c-2.7 2.7-6 4.7-9.7 5.8A39.3 39.3 0 01401 263h-.3c-5.6 0-16.9-2-21.9-9-3.2-4.8-18.1-26.1-19.5-55v-8c0-2.2.3-4.4.5-6.7l.8-4c1.5-5.7 4.6-13.8 8.4-22.9-7.9 35.4.4 54.4 8.3 73.9 7.1 17.5 32.3 28.1 44.4 24h.1z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M443.5 195.1h.4v-4.4l-.1-2-.1-1.5-.3-3a34 34 0 00-.9-4.2l-1.1-4.7-.1.3c-2.6-8-7.2-18.8-12-30.2-9.2-21.6-19.6-46.1-19.6-59V5.9c0-3.3-3.9-5.9-8.9-5.9S392 2.5 392 5.8v80.6c0 12.9-10.4 37.4-19.6 59l-4.8 11.4c-3.3 7.9-6.9 16.8-8.5 23.2a36 36 0 00-.8 4.1l-.3 3v1.7l-.2 1.9v8.6a108 108 0 0019.5 55.1l.3.5c6.5 9.1 21.4 9.6 23.1 9.6h.3c4-.1 7.9-.8 11.6-2 3.7-1 7-3 9.8-5.7l.5-.5 1.2-1.5.3-.4c12-17.8 18.5-36.4 19.5-55.2v-4l-.4-.1z'
          ></path>
        </g>
        <ellipse
          cx='217.8'
          cy='230.6'
          fill='#e6e6e6'
          rx='37.1'
          ry='11'
        ></ellipse>
        <g className='vases__vase' onClick={handleNote('1')}>
          <path
            fill='#8c5125'
            fillRule='nonzero'
            d='M259.4 165.3c0 2.6-.3 5.2-.6 7.8l-.5 4.1v.1l-1.7 12.1c-1.2 7.1-2.7 14.2-4.5 21.2-2 8.3-4 14.4-5.2 18.5-1.1 5.4-11.3 9.5-23.8 9.5-11.9 0-21.8-3.8-23.5-8.8L196 218c-4.1-14.7-7-29.7-8.6-44.9v-.3l-.6-6.9c-1.1-21.1 8.3-22.9 15.1-30.8 2.7-3.2 4.6-7 5.5-11.2 4.9-20.9 5.4-115.6 5.4-115.6L223 8h.2l10.2.1s.6 94.7 5.4 115.6c4.7 20.2 22 13.8 20.6 41.6z'
          ></path>
          <ellipse
            cx='223.1'
            cy='8.2'
            fill='#bc784a'
            rx='10.3'
            ry='4.5'
          ></ellipse>
          <path
            fill='#8c5125'
            fillRule='nonzero'
            d='M232.6 8.2c0 2.1-4.3 3.9-9.5 3.9h-.1c-5.2 0-9.3-1.8-9.3-4s4.1-3.8 9.3-3.8h.1c5.2 0 9.5 1.7 9.5 3.9z'
          ></path>
          <path
            fill='#7a431e'
            fillRule='nonzero'
            d='M232.6 8.2c0 2.1-4.3 3.9-9.5 3.9h-.1V4.3h.1c5.2 0 9.5 1.7 9.5 3.9z'
          ></path>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M258.5 173.5l-.2 3.7v.1l-1.7 12.1c-.9 5-1.9 9.9-3 14.8a57.6 57.6 0 01-30.4 7.2 75 75 0 01-17.2-2 40 40 0 01-13.5-5.4c-2.3-10.2-4-20.6-5-31 1.9 2.8 5.4 5.3 10.2 7.3a79.5 79.5 0 0045 2c7.5-2 13.1-5.1 15.8-8.8z'
          ></path>
          <path
            fill='#7a431e'
            fillOpacity='0.3'
            fillRule='nonzero'
            d='M246.9 229.1c-1.1 5.4-11.3 9.5-23.8 9.5-11.9 0-21.8-3.8-23.6-8.8L196 218c-4.1-14.7-7-29.7-8.6-44.9v-.3l-.6-6.9c-1.1-21.1 8.3-22.9 15.1-30.8l.3.2c-16.4 23.6 1.2 66 5 79 5.5 18 26.7 19.4 39.7 15v-.2z'
          ></path>
          <path
            fill='#b58f76'
            fillOpacity='0.1'
            fillRule='nonzero'
            d='M244.9 146.5c4.4 10.1 5.5 29 1.5 49.5s-8.3 7.1-6.7-11.7c1.6-18.8.4-26-4-36.5s5.7-9.3 9.2-1.3z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M250.2 138.6c-4.1-3.6-8-7-10-15.2-4.6-20.2-5.3-110.5-5.3-115v-.2c0-4-6-6-11.8-6-5.8 0-11.9 2-11.9 6v.1c0 5.5-.6 94.9-5.3 115-.8 4-2.6 7.6-5.2 10.7-1.5 1.6-3 3.2-4.7 4.6-5.7 5-11.6 10.2-10.7 27.3.1 2.3.3 4.7.6 7v.2c1.7 15.3 4.6 30.4 8.7 45.2l2.5 8.6 1 3.3.3 1.1h.2c2.8 5.2 12.6 8.8 24.5 8.8 13.3 0 23.9-4.5 25.2-10.6l.8-2.4a349 349 0 006-22.6c2-9 3.6-18 4.7-27v-.1l.4-4.1.5-4.5.2-3.4c.9-16.7-5-21.8-10.7-26.8z'
          ></path>
        </g>
        <ellipse
          cx='87.1'
          cy='226.1'
          fill='#e6e6e6'
          rx='33.5'
          ry='12.9'
        ></ellipse>
        <g className='vases__vase'>
          <ellipse
            cx='90'
            cy='220.4'
            fill='#c4ac8a'
            rx='26.6'
            ry='15.8'
          ></ellipse>
          <path
            fill='#d8c4a3'
            fillRule='nonzero'
            d='M116.8 206.8c-2 4.7-4.3 9.5-6.8 14.2-13.8 9-27.3 9-40.3-.6-16.5-36.2-24.8-69.7-1.6-81.4 1.5-.7 3-1.4 4.5-1.9h.4c2.9 0 20.1.5 33.6 0 9.9 3.7 17 12.3 18.9 22.7 2.7 13.4-1.5 30.2-8.7 47z'
          ></path>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M116.8 206.8a25 25 0 01-17.6 8c-18.6 0-33.6-24.5-33.6-54.9 0-7 .8-14 2.4-20.9 1.5-.7 3-1.4 4.5-1.9h.4c2.9 0 20.1.5 33.6 0 9.9 3.7 17 12.3 18.9 22.7 2.7 13.4-1.5 30.2-8.7 47h.1z'
          ></path>
          <path
            fill='#f4e4cb'
            fillRule='nonzero'
            d='M125.5 159.8c-3.5 13.4-11.3 22.8-21.2 23.8-14.8 1.3-28.7-17.2-31-41.5l-.3-5c2.9 0 20.2.5 33.7 0 9.8 3.7 17 12.4 18.8 22.7z'
          ></path>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M90.5 129.5h-2.8l-14.7.6v9.5c0 5.6 7.6 10.2 17 10.2s17-4.6 17-10.2V129l-16.5.5z'
          ></path>
          <ellipse
            cx='90'
            cy='125'
            fill='#f4e4cb'
            rx='24.5'
            ry='14.6'
          ></ellipse>
          <path
            fill='#e5d1ac'
            fillRule='nonzero'
            d='M107 125c0 5.6-7.6 10.2-17 10.2s-17-4.6-17-10.2 7.6-10.2 17-10.2 17 4.6 17 10.2z'
          ></path>
          <path
            fill='#ccb58f'
            fillRule='nonzero'
            d='M107 125c0 5.6-7.6 10.2-17 10.2v-20.3c9.4 0 17 4.5 17 10.1z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M127 159.5a31 31 0 00-18.4-23.2c4.8-3 7.4-7 7.4-11.3 0-9-11.4-16.1-26-16.1s-26 7-26 16.1c0 4.2 2.5 8 7 11a35 35 0 00-3.6 1.7c-19.2 9.7-20 34.3-2.7 75.2a12.3 12.3 0 00-2.7 7.5c0 9.7 12.3 17.4 28 17.4s28.1-7.7 28.1-17.4c0-2.6-1-5.1-2.5-7.2 1-2 1.8-4 2.6-5.9 8.3-19.3 11.2-35.4 8.8-47.8z'
          ></path>
        </g>
        <path
          fill='#e6e6e6'
          fillRule='nonzero'
          d='M76 251l-33.6 16.6L0 251.2l36-18.5L76 251z'
        ></path>
        <g fillRule='nonzero' className='vases__vase'>
          <path
            fill='#f39927'
            d='M10.7 209.6l31-13.7 31.6 13.7L42 223.3l-31.4-13.7h.1z'
          ></path>
          <path
            fill='#e28723'
            d='M15 209.6l26.8-11.8L69 209.6l-27 11.8-27-11.8z'
          ></path>
          <path
            fill='#f2b02a'
            d='M42 264.4v-41.1l31.3-13.7v41.1L42 264.4z'
          ></path>
          <path
            fill='#e28723'
            d='M10.7 209.6L42 223.3v41.1l-31.4-13.7v-41.1h.1z'
          ></path>
          <path
            fill='#e06724'
            d='M41.8 197.8l.2 23.6 27-11.8-27.2-11.8z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            d='M41.7 194.3L9.1 208.6v43L42 266l32.8-14.3v-43l-33-14.4h-.1z'
          ></path>
        </g>
        <ellipse cx='148.2' cy='265.4' fill='#e6e6e6' rx='38' ry='9'></ellipse>
        <g className='vases__vase' onClick={handleNote('3')}>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M198.5 210.1v.6c-.6 12.5-20.4 22.6-44.7 22.6h-2.3l-2.2-.1-1.9-.1h-.5l-2-.2h-.1l-1.8-.2c-3-.3-6-.9-9-1.7l-4.8-1.5-1.5-.5c-1.4-.5-2.7-1.1-4-1.8l-.6-.2-.9-.5a45 45 0 01-2.6-1.5l-1.1-.7h-.1l-1-.7-1.1-1c-.4-.1-.5-.3-.8-.6l-1-1-.8-.7-.1-.1c-.7-.7-1.3-1.4-1.8-2.2l-.4-.5a17.6 17.6 0 01-2-4.9l-.2-1.2v-1.3c0-1.8 0-3.6.2-5.4.5-6.3 2-12.5 4.4-18.4 9.2-22.5 28.8-33.8 28.8-45.6V75.4h22.6v65.3c0 14.6 30.3 28.6 33.2 64l.1 5.4z'
          ></path>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M198.5 210.7c0 3-.2 6-.6 9-1.4 10.3-5.8 22-17.6 42l-.2.3-26.3-3.9-12.2 1.8-1.9.3-12.2 1.8-.1-.3-5.9-10.2a93.1 93.1 0 01-11.7-31.8c-.4-3-.6-6-.6-9v.7c0 .4 0 .8.2 1.2a11.8 11.8 0 001.1 3.2l.3.6a17.4 17.4 0 002.7 3.7l.1.2.7.7 1.1 1 .8.6 1.1 1 1 .6 1.2.8.9.5 2.6 1.4.5.3 1 .5a76.7 76.7 0 009.5 3.2c3 .7 6 1.3 9 1.7l1.7.2h.2l1.9.2h.5l4 .2h2.4c24.4 0 44.2-10 44.8-22.5z'
          ></path>
          <ellipse
            cx='153.8'
            cy='75.4'
            fill='#a26330'
            rx='11.3'
            ry='5.9'
          ></ellipse>
          <ellipse
            cx='153.8'
            cy='75.4'
            fill='#bc784a'
            rx='10'
            ry='5.2'
          ></ellipse>
          <path
            fill='#a26330'
            fillRule='nonzero'
            d='M142.4 141.7c1.6 2.5 6.1 4.3 11.4 4.3 5.3 0 9.9-1.8 11.4-4.3v-4c-.2 3.3-5.2 5.8-11.4 5.8s-11.1-2.5-11.3-5.7l-.1 3.9z'
          ></path>
          <path
            fill='#bc784a'
            fillRule='nonzero'
            d='M181.2 258a8 8 0 01-1.1 3.9c-3.2 6-13.7 10.4-26.3 10.4l-2.9-.1c-11.2-.6-20.4-4.8-23.4-10.3l-.1-.3a8 8 0 01-.9-3.5c0-2.8 1.5-5.3 4.1-7.5 1.9-1.5 4-2.7 6.2-3.6 5.4-2 11.2-3.1 17-3 15.1 0 27.4 6.3 27.4 14z'
          ></path>
          <path
            fill='#8c5125'
            fillRule='nonzero'
            d='M163.8 75.4c0 2.9-4.4 5.2-10 5.2V70.3c5.6 0 10 2.3 10 5.1z'
          ></path>
          <path
            fill='#b58f76'
            fillOpacity='0.1'
            fillRule='nonzero'
            d='M184.2 216c2.1-7 3.7-23.7-4.5-38s-18.9-22.1-19.7-11.4c-.8 10.7 15.5 40.5 16.3 47.5.8 7 7.9 2 7.9 2v-.1z'
          ></path>
          <path
            fill='#a26330'
            fillRule='nonzero'
            d='M198.5 210.7c0 3-.2 6-.6 9-6.3 9.5-23.6 16.3-44 16.3s-37.9-6.8-44.1-16.3c-.4-3-.6-6-.6-9 .6 12.5 20.4 22.6 44.6 22.6 24.2 0 44-10 44.7-22.6z'
          ></path>
          <path
            fill='#7a431e'
            fillOpacity='0.3'
            fillRule='nonzero'
            d='M180.1 262c-3.2 6-13.7 10.3-26.3 10.3l-2.9-.1c-11.2-.6-20.4-4.8-23.4-10.3l-.1-.3c-2-3.4-4-6.8-5.8-10.2a93.1 93.1 0 01-11.8-31.7c-.4-3-.6-6-.6-9v-.6c0-1.9 0-3.7.2-5.4.5-6.4 2-12.6 4.4-18.5-.6 15.2.5 26 4.5 38a160 160 0 0010.5 23.3l1.8 3.1c2.4 3.8 5.5 7 9.2 9.5 13.4 9.2 31 5.3 40.3 1.8v.1z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M199.8 204.6c-1.8-21.6-13.3-35.2-22.5-46-5.6-6.7-10.1-12-10.6-17V73.8h-.3c-1.2-3.4-6.3-5.8-12.6-5.8-7.2 0-12.8 3.2-12.8 7.3v66.3c-.6 5-5 10.2-10.6 16.8-6 7-13.4 15.7-18 27.1l-.1.3a63 63 0 00-4.4 18.6c-.2 1.8-.2 3.7-.2 5.6v.6c0 3 .2 6.2.6 9.2a93 93 0 0012 32.2c1.7 3.3 3.6 6.7 5.8 10.3l.1.3c3.3 6 13 10.3 24.7 11h3c12.9 0 24-4.4 27.6-11l.1-.3c11.1-18.7 16.2-31 17.8-42.5.4-3 .6-6 .6-9.1v-.7c0-1.9 0-3.8-.2-5.5v.1z'
          ></path>
        </g>
        <ellipse
          cx='264.3'
          cy='291.4'
          fill='#e6e6e6'
          rx='50.3'
          ry='12.3'
        ></ellipse>
        <g fillRule='nonzero' className='vases__vase' onClick={handleNote('2')}>
          <path
            fill='#a26330'
            d='M323.9 229.3c0 34.5-17 57.9-17 57.9h-.1c-1.1 1.3-12.3 13-36.4 13.2h-.5c-25.4-.1-36.4-13.2-36.4-13.2s-17-23.3-17-58c0-17 9.8-31.4 19.9-46 10.4-15 21-30 21-47.5v-59c0 3.6 4.2 7.5 12.8 7.5 8.5 0 12.8-4 12.8-7.5v59c0 34.5 40.9 59 40.9 93.6z'
          ></path>
          <path
            fill='#bc784a'
            d='M217.5 219.6v.3c0 15.4 23.6 27.8 52.6 27.8h.2v-4.4h-.2c-27.2 0-49.6-10.9-52.3-24.9l-.3 1.2z'
          ></path>
          <path
            fill='#bc784a'
            d='M270.3 240.3v1.5h-.2c-26.7 0-48.8-10.5-52.1-24.1l.2-.8c4 13.3 25.7 23.4 51.9 23.4h.2zm52.3-20.8v.4c0 15.3-23.5 27.7-52.5 27.8h-.2v-4.4h.2c27.2 0 49.6-11 52.2-25l.3 1.2z'
          ></path>
          <path
            fill='#bc784a'
            d='M270 240.3v1.5h.1c26.7 0 48.7-10.6 52-24.2l-.1-.7c-4 13.2-25.7 23.3-51.9 23.4h-.1zm13-163.4c0 1.9-1.2 3.6-3.1 5a20.6 20.6 0 01-9.8 2.5 21 21 0 01-5.6-.7c-1.4-.4-2.8-1-4-1.8-2-1.3-3.2-3-3.2-5 0-4.1 5.7-7.5 12.8-7.5 7.1 0 12.9 3.3 12.9 7.5z'
          ></path>
          <path
            fill='#8c5125'
            d='M281.8 77c0 3.5-5.2 6.3-11.7 6.3-6.5 0-11.7-2.8-11.7-6.4 0-3.6 5.3-6.4 11.7-6.4 6.5 0 11.7 2.9 11.7 6.4v.1z'
          ></path>
          <path
            fill='#a26330'
            d='M270.1 70.5v12.8c-6.5 0-11.7-2.8-11.7-6.4 0-3.6 5.2-6.4 11.7-6.4z'
          ></path>
          <path
            fill='#b58f76'
            fillOpacity='0.1'
            d='M295 189.8a98.2 98.2 0 0117.4 66.5c-.3 14.8-15.2 6-18-11.3-2.8-17.3-4.4-33.5-12.6-48.8-8.2-15.3 6.8-14.8 13.2-6.3v-.1z'
          ></path>
          <path
            fill='#7a431e'
            fillOpacity='0.3'
            d='M306.8 287.1v.2c-1.1 1.2-12.3 13-36.4 13.1h-.5c-25.4-.2-36.4-13.2-36.4-13.2s-17-23.4-17-58c0-17 9.8-31.5 19.9-46-9.3 25.8-11.7 47.8-1 74.1 10 24.7 43 38 71.4 29.8z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            d='M304.6 181.6c-9.9-14.3-20.1-29.2-20.1-46V76.7c-.2-5-6.4-8.8-14.4-8.8s-14.3 4-14.3 9v58.7c0 16.8-10.2 31.7-20.1 46l-.5.8c-5.7 8-11.9 17-15.9 26.7l-3.2 10.1v.2c-.7 3.3-1 6.6-1 9.9 0 34.6 16.5 57.8 17.2 58.8.5.6 11.4 13.1 36.2 13.8h3.5c23.6-.6 34.8-12.3 36-13.6l.1-.3c.7-1 17.3-24 17.3-58.7 0-17.8-10.6-33-20.8-47.7z'
          ></path>
        </g>
        <ellipse
          cx='452.1'
          cy='287'
          fill='#e6e6e6'
          rx='38.1'
          ry='11.6'
        ></ellipse>
        <g className='vases__vase'>
          <path
            fill='#e28723'
            fillRule='nonzero'
            d='M488.4 279.2c0 9.4-14.2 17-31.7 17s-31.6-7.6-31.6-17c0-36.8 6.4-80.7 8.3-95.8H480a624.4 624.4 0 018.3 95.8h.1z'
          ></path>
          <path
            fill='#f39927'
            fillRule='nonzero'
            d='M488.4 276.4c-4.6 4-9.7 6.1-15.1 6.1-20.3 0-36.8-31.1-36.8-69.6 0-10 1.2-19.8 3.5-29.5h40c2.3 12.2 8.1 52.9 8.4 93z'
          ></path>
          <path
            fill='#f2b02a'
            fillRule='nonzero'
            d='M487.4 247.7c-3.2 2.5-7 4-11.1 4-16.6 0-30-25.5-30-56.8 0-3.8.2-7.7.6-11.5h33.2c3.5 21.3 6 42.8 7.3 64.3z'
          ></path>
          <ellipse
            cx='456.7'
            cy='183.4'
            fill='#f39927'
            rx='23.4'
            ry='12.5'
          ></ellipse>
          <path
            fill='#e28723'
            fillRule='nonzero'
            d='M477 183.4c0 6-9 10.8-20.3 10.8s-20.1-4.8-20.1-10.8c0-6 9-10.8 20.1-10.8 11.1 0 20.2 4.8 20.2 10.8h.1z'
          ></path>
          <path
            fill='#e06724'
            fillRule='nonzero'
            d='M477 183.4c0 6-9 10.8-20.3 10.8v-21.6c11.2 0 20.2 4.8 20.2 10.8h.1z'
          ></path>
          <path
            fill='none'
            className='vases__outline'
            fillRule='nonzero'
            d='M490 276.4l-.2-7v-.4c0-2.3 0-4.6-.2-6.9v-.3c-.6-15.7-1.8-31.4-3.6-47l-.4-3-.7-5.7-.3-2.7-.7-5-.3-2.4-.6-4.3-.5-3.2-.3-2-.6-3.3c-.1-7.8-11-13.9-24.9-13.9-13.9 0-24.7 6.1-24.8 14l-1 7a739 739 0 00-7.3 88.8c0 10.4 14.5 18.5 33.1 18.5s33.2-8 33.2-18.5v-2.7h.1z'
          ></path>
        </g>
      </svg>
    </div>
  );
});

export default Vases;
