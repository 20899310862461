import { memo, useCallback } from 'react';
import { Item, useSetView, ItemMeta } from '@lib/state';
import Button from '@components/Button';
import { motion } from 'framer-motion';

type FlyerItemProps = {
  flyer?: Item;
};

const FlyerItem = memo(({ flyer }: FlyerItemProps) => {
  const setView = useSetView();
  const showDetail = useCallback(() => {
    if (flyer) {
      const flyerView = ItemMeta?.[flyer]?.triggersView;

      if (flyerView) {
        setView(flyerView);
      }
    }
  }, [setView, flyer]);

  if (!flyer) {
    return (
      <div className='flyer__item'>
        <div className='flyer__placeholder'></div>
        <Button disabled>Flyer ansehen</Button>
      </div>
    );
  }

  let src = ItemMeta?.[flyer]?.imageSrc;
  if (src) {
    src = Array.isArray(src) ? src[0] : src;
  }

  return (
    <div className='flyer__item'>
      <motion.img
        src={src}
        alt={flyer}
        className='flyer__item-thumb'
        onClick={showDetail}
        layoutId={flyer}
      />
      <Button onClick={showDetail}>Flyer ansehen</Button>
    </div>
  );
});

export default FlyerItem;
