import { memo } from 'react';
import { useGoToExplore } from '@lib/state';
import Overlay, { OverlayVariant } from '@components/Overlay';
import Button from '@components/Button';
import Heading, { HeadingSize } from '@components/Heading';
import LogoBMS from '@img/logos/bms.svg';

const Exit = memo(() => {
  const close = useGoToExplore();

  return (
    <Overlay
      variant={OverlayVariant.Small}
      layerClassName='exit'
      handleClose={close}
    >
      <img src={LogoBMS} alt='Bristol Myers Squibb' className='exit__logo' />

      <Heading size={HeadingSize.H2}>Sie müssen schon gehen?</Heading>

      <p className='exit__text'>
        Um den Spielstand zu speichern und auf die BMS Webseite zu wechseln,
        klicken Sie auf „Wohnzimmer verlassen“.
      </p>

      <Button href='https://link.b-ms.de/3HulO2I'>
        Wohnzimmer verlassen
      </Button>
    </Overlay>
  );
});

export default Exit;
