import { Children, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MotionVariant } from '@lib/motion';
import Button from '@components/Button';
import { useHints } from '@lib/state';

type HintsProps = {
  id: string;
  children: React.ReactNode;
  intro?: React.ReactNode;
  outro?: React.ReactNode;
  hasSolution?: boolean;
};

const defaultIntro = (
  <p>
    Sie kommen nicht weiter? <br />
    Dann finden Sie hier einen Tipp.
  </p>
);

const Hints = memo(
  ({
    id,
    children,
    hasSolution = true,
    intro = defaultIntro,
    outro,
  }: HintsProps) => {
    const [requestedHints, requestHint] = useHints(id);
    const hintCount = Children.count(children);
    const renderHintCount = hasSolution ? hintCount - 1 : hintCount;

    // render hints
    const hints = Children.map(children, (child, index) => {
      if (index >= requestedHints) {
        return;
      }

      return (
        <motion.div
          className='hints__hint'
          variants={MotionVariant.FadeUp}
          key={index}
        >
          <h5 className='hints__title'>
            {hasSolution && index === hintCount - 1
              ? 'Lösung'
              : renderHintCount === 1
              ? 'Tipp'
              : `Tipp ${index + 1}/${renderHintCount}`}
          </h5>
          {child}
        </motion.div>
      );
    });

    return (
      <div className='hints'>
        {requestedHints === 0 ? (
          intro
        ) : (
          <AnimatePresence>{hints}</AnimatePresence>
        )}
        {requestedHints !== hintCount && (
          <div className='hints__action'>
            <Button onClick={requestHint}>
              {hasSolution && requestedHints === hintCount - 1
                ? 'Lösung anzeigen'
                : 'Tipp anzeigen'}
            </Button>
          </div>
        )}
        {outro ? <div className='hints__outro'>{outro}</div> : null}
      </div>
    );
  }
);

export default Hints;
