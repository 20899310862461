import { useCallback, useState } from 'react';
import { useSound } from '@lib/sfx';
import { Sound, SoundFile } from '@lib/entities';

const emptyArr: string[] = [];

const useSoundCombination = (
  sound: Sound,
  solution: string[],
  onSolve?: () => void
) => {
  const [combination, setCombination] = useState<string[]>([]);
  const [play] = useSound(sound);
  const sfx = SoundFile[sound];
  const sprites = Array.isArray(sfx) ? Object.keys(sfx[1]) : emptyArr;

  const handleNote = useCallback(
    (id: typeof sprites[number]) => () => {
      if (sprites.includes(id)) {
        play({ id });
        const nextCombination = combination.concat(id);

        if (nextCombination.every((val, index) => val === solution[index])) {
          if (nextCombination.length === solution.length) {
            // melody has been played, solved!
            if (onSolve) {
              onSolve();
            }
          } else {
            // we’re not done yet, append note
            setCombination((prevCombination) => prevCombination.concat(id));
          }
        } else {
          setCombination([]);
        }
      }
    },
    [play, combination, solution, onSolve, sprites]
  );

  return [handleNote] as const;
};

export default useSoundCombination;
