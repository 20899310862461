import { memo } from 'react';
import IconButton from '@components/IconButton';
import { Greenhouse } from '@components/Icon';
import { CalloutBubble } from '@components/Bubble';
import {
  Callout,
  useFound,
  Item,
  useWasUsed,
  ItemGroup,
  useGoToView,
  View,
} from '@lib/state';

const GreenhouseButton = memo(() => {
  const goToGreenhouse = useGoToView(View.Greenhouse);
  const found = useFound();
  const keysFound = ItemGroup.Keys.filter((key) => found.includes(key));
  const keysUsed = useWasUsed(ItemGroup.Keys);
  const visible = found.includes(Item.Letter) || keysFound.length > 0;

  const msg =
    keysFound.length === 0 || !found.includes(Item.Letter)
      ? 'Hier können Sie das Gewächshaus aufschließen. '
      : 'Es wurde ein Schlüssel hinzugefügt.';

  return visible ? (
    <CalloutBubble
      content={msg}
      callout={Callout.Greenhouse}
      dismissInViews={View.Explore}
    >
      <IconButton
        onClick={goToGreenhouse}
        Icon={Greenhouse}
        badge={
          keysFound.length > 0 && keysFound.length > keysUsed ? '!' : undefined
        }
      />
    </CalloutBubble>
  ) : null;
});

export default GreenhouseButton;
