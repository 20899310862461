import { useCallback } from 'react';
import { useUpdateEffect } from 'react-use';
import { useSound, Sound } from '@lib/sfx';
import { useCombination } from '@lib/hooks';

const useKeypadCombination = (
  initialCombination: Combination,
  solution: Combination,
  onSolve?: () => void
) => {
  const [combination, error, setCombination, check, reset] = useCombination(
    initialCombination,
    solution,
    onSolve
  );
  const [keySfx] = useSound(Sound.Button);

  /**
   * adds a digit to current combination
   * @param {number} digit
   */
  const addDigit = useCallback(
    (digit: number) => {
      setCombination((prevCombination) => {
        if (error) {
          return [digit];
        }

        if (prevCombination.length < solution.length) {
          return [...prevCombination, digit];
        }

        return prevCombination;
      });
    },
    [solution, error, setCombination]
  );

  // play button sound if combination is changed
  useUpdateEffect(() => {
    keySfx();
  }, [combination]);

  return [combination, error, addDigit, check, reset] as const;
};

export default useKeypadCombination;
