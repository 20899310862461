import { useState, useCallback } from 'react';

const useIndexPagination = <T>(
  items: T[],
  defaultIndex: number = 0,
  perPage: number = 5
) => {
  // prevent over/underflow of items
  const guardIndex = useCallback(
    (index: number) =>
      Math.max(
        0,
        Math.min(Math.floor(index / perPage) * perPage, items.length - perPage)
      ),
    [items, perPage]
  );

  // track index
  const [currentIndex, setCurrentIndex] = useState(guardIndex(defaultIndex));

  // status
  const isFirst = currentIndex <= 0;
  const isLast = currentIndex >= items.length - perPage;

  // navigation
  const goPrevious = useCallback(
    () => setCurrentIndex((index) => guardIndex(index - perPage)),
    [setCurrentIndex, guardIndex, perPage]
  );

  const goNext = useCallback(
    () => setCurrentIndex((index) => guardIndex(index + perPage)),
    [setCurrentIndex, guardIndex, perPage]
  );

  return {
    currentIndex,
    isFirst,
    isLast,
    goNext,
    goPrevious,
  };
};

export default useIndexPagination;
