import { useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue, RecoilState } from 'recoil';
import persistEffect from '@lib/state/persistEffect';

/**
 * requested hints state
 */
export const requestedHintsState: RecoilState<{ [id: string]: number }> = atom({
  key: 'requestedHints',
  default: {},
  effects_UNSTABLE: [persistEffect()],
});

/**
 * track requested hints by id
 */
export const useHints = (id: string) => {
  const [requestedHints, setRequestedHints] = useRecoilState(
    requestedHintsState
  );

  const requestHint = useCallback(
    () =>
      setRequestedHints((prevRequestedHints) => ({
        ...prevRequestedHints,
        [id]: (prevRequestedHints?.[id] ?? 0) + 1,
      })),
    [setRequestedHints, id]
  );

  return [requestedHints?.[id] ?? 0, requestHint] as const;
};

export const useRequestedHints = () => useRecoilValue(requestedHintsState);
