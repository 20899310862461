import { memo } from 'react';

type UIProps = {
  topLeft?: React.ReactNode;
  topCenter?: React.ReactNode;
  topRight?: React.ReactNode;
  bottomLeft?: React.ReactNode;
  bottomCenter?: React.ReactNode;
  bottomRight?: React.ReactNode;
};

const UI = memo(
  ({
    topLeft,
    topCenter,
    topRight,
    bottomLeft,
    bottomCenter,
    bottomRight,
  }: UIProps) => {
    return (
      <>
        {topLeft ? <div className='ui ui--top-left'>{topLeft}</div> : null}
        {topCenter ? (
          <div className='ui ui--top-center'>{topCenter}</div>
        ) : null}
        {topRight ? <div className='ui ui--top-right'>{topRight}</div> : null}
        {bottomLeft ? (
          <div className='ui ui--bottom-left'>{bottomLeft}</div>
        ) : null}
        {bottomCenter ? (
          <div className='ui ui--bottom-center'>{bottomCenter}</div>
        ) : null}
        {bottomRight ? (
          <div className='ui ui--bottom-right'>{bottomRight}</div>
        ) : null}
      </>
    );
  }
);

export default UI;
