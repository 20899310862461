import { memo } from 'react';
import { Callout, useCallout, View } from '@lib/state';
import Bubble from '@components/Bubble';

type CalloutBubbleProps = {
  callout: Callout;
  children: React.ReactNode;
  floatOnTop?: boolean;
  content: React.ReactNode;
  dismissInViews?: View | View[];
};

const CalloutBubble = memo(
  ({
    callout,
    children,
    content,
    dismissInViews,
    floatOnTop,
  }: CalloutBubbleProps) => {
    const [currentCallout, dismiss] = useCallout(dismissInViews);

    return (
      <Bubble
        className='bubble--callout'
        content={content}
        handleClose={dismiss}
        visible={currentCallout === callout}
        closeOnChildClick
        floatOnTop={floatOnTop}
      >
        {children}
      </Bubble>
    );
  }
);

export default CalloutBubble;
