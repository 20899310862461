import { useCallback } from 'react';
import {
  atom,
  selector,
  RecoilState,
  useSetRecoilState,
  useRecoilValue,
} from 'recoil';

// duration of elapsed without progress after which user needs help
const NEEDS_HELP_AFTER = 60 * 1000;

/**
 * Elapsed time without progress
 */

export const elapsedWithoutProgressState: RecoilState<number> = atom({
  key: 'elapsedWithoutProgress',
  default: 0,
});

/**
 * returns true if user needs help
 */
const needsHelpState = selector({
  key: 'needsHelpState',
  get: ({ get }) => {
    const elapsedWithoutProgress = get(elapsedWithoutProgressState);
    return elapsedWithoutProgress > NEEDS_HELP_AFTER;
  },
});

export const useNeedsHelp = () => useRecoilValue(needsHelpState);

/**
 * reset timer
 */
export const useResetElapsedWithoutProgress = () => {
  const setElapsed = useSetRecoilState(elapsedWithoutProgressState);
  return useCallback(() => setElapsed(0), [setElapsed]);
};
