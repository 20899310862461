import { useMemo } from 'react';
import _useSound from 'use-sound';
import { useMuted } from '@lib/state';
import { Sound, SoundFile } from '@lib/entities';

const emptyObj = {};
export const useSound = (sound: Sound, options = emptyObj) => {
  const sfx = SoundFile[sound];
  const filePath = Array.isArray(sfx) ? sfx[0] : sfx;
  const muted = useMuted();

  const sfxConfig = useMemo(
    () => ({
      soundEnabled: !muted,
      ...(Array.isArray(sfx) ? { sprite: sfx[1] } : {}),
      ...options,
    }),
    [muted, options, sfx]
  );

  return _useSound(filePath, sfxConfig);
};

export { Sound };
