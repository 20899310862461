import { atom, RecoilState, useRecoilState, useRecoilValue } from 'recoil';

/**
 * Elapsed time
 */

export const loadedState: RecoilState<number> = atom({
  key: 'loaded',
  default: 0,
});

/**
 * returns loaded state
 */
export const useLoaded = () => useRecoilValue(loadedState);

export const useSetLoaded = () => {
  const [, setLoaded] = useRecoilState(loadedState);
  return setLoaded;
};
