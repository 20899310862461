import { memo } from 'react';
import { motion, MotionProps } from 'framer-motion';
import classNames from 'classnames';
import { MotionVariant } from '@lib/motion';
import { Close } from '@components/Icon';
import { useKey } from 'react-use';

export enum OverlayVariant {
  Small = 'small',
  Large = 'large',
  Note = 'note',
}

type OverlayProps = {
  children?: React.ReactNode;
  className?: string;
  layerClassName?: string;
  backdropClassName?: string;
  initial?: MotionProps['initial'];
  animate?: MotionProps['animate'];
  exit?: MotionProps['exit'];
  variant?: OverlayVariant;
  handleClose?: () => void;
  backdropCloses?: boolean;
  escapeCloses?: boolean;
};

/**
 * Overlay with backdrop
 */
const Overlay = memo(
  ({
    children,
    className,
    layerClassName,
    backdropClassName,
    initial = 'initial',
    animate = 'animate',
    exit = 'exit',
    variant = OverlayVariant.Small,
    handleClose,
    backdropCloses = true,
    escapeCloses = true,
  }: OverlayProps) => {
    useKey('Escape', handleClose);

    return (
      <div
        className={classNames('overlay', className, {
          [`overlay--${variant}`]: variant,
        })}
      >
        <motion.div
          className={classNames('overlay__backdrop', backdropClassName)}
          variants={MotionVariant.Fade}
          initial={initial}
          animate={animate}
          exit={exit}
          onClick={backdropCloses ? handleClose : undefined}
        />
        <motion.div
          className={classNames('overlay__layer', layerClassName)}
          variants={MotionVariant.GrowShort}
          initial={initial}
          animate={animate}
          exit={exit}
        >
          {children}
          {handleClose ? (
            <button className='overlay__close' onClick={handleClose}>
              <Close />
            </button>
          ) : null}
        </motion.div>
      </div>
    );
  }
);

export default Overlay;
