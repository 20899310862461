import { memo } from 'react';
import classNames from 'classnames';

type HoverZoomThumbProps = {
  src: string;
  thumbRef: React.RefObject<HTMLDivElement>;
  className?: string;
  area?: { width: number; height: number; left: number; top: number };
};

const HoverZoomThumb = memo(
  ({ thumbRef, src, area, className }: HoverZoomThumbProps) => {
    return (
      <div
        ref={thumbRef}
        className={classNames('hover-zoom__thumb', className)}
      >
        <img src={src} alt='' />
        {area && <div className='hover-zoom__area' style={area}></div>}
      </div>
    );
  }
);

export default HoverZoomThumb;
