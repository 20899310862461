import { memo } from 'react';
import { WindowContent } from '@components/Window';
import Solved from '@components/Solved';
import VaseSolved from '@img/vase/solved.svg';
import VaseKey from '@img/keys/vase.svg';

type VaseGameSolvedProps = {
  animate: boolean;
};

const VaseGameSolved = memo(({ animate }: VaseGameSolvedProps) => {
  return (
    <WindowContent
      heading='Das klingt harmonisch.'
      intro={
        <p>
          Sie haben die Melodie richtig nachgespielt. In einer der Vasen finden
          Sie einen Schlüssel.
          <br /> Ob dieser ins Schloss des Gewächshauses passt?
        </p>
      }
      animate={animate}
    >
      <Solved imageSrc={VaseSolved} itemSrc={VaseKey} animate={animate} />
    </WindowContent>
  );
});

export default VaseGameSolved;
