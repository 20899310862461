import { useCallback } from 'react';
import arrayMove from 'array-move';
import { useCombination } from '@lib/hooks';
import { useMeasure } from 'react-use';

const useDragCombination = (
  initialCombination: Combination,
  solution: Combination,
  onSolve?: () => void
) => {
  const [combination, error, setCombination, check] = useCombination(
    initialCombination,
    solution,
    onSolve
  );

  // measure item width
  const [itemRef, { width: itemWidth }] = useMeasure<HTMLDivElement>();

  // update order based on drag offset
  const updateOrder = useCallback(
    (combinationItem: CombinationItem, offset: number) => {
      setCombination((prevCombination) => {
        const currentIndex = prevCombination.indexOf(combinationItem);
        const nextIndex = Math.max(
          Math.min(
            Math.round(offset / itemWidth) + currentIndex,
            prevCombination.length - 1
          ),
          0
        );

        if (currentIndex === nextIndex) {
          return prevCombination;
        }

        return arrayMove(prevCombination, currentIndex, nextIndex);
      });
    },
    [itemWidth, setCombination]
  );

  return [combination, error, updateOrder, check, itemRef] as const;
};

export default useDragCombination;
