import { memo } from 'react';
import { motion } from 'framer-motion';
import { MotionVariant } from '@lib/motion';
import Heading from '@components/Heading';

type WindowContentProps = {
  heading?: React.ReactNode;
  intro?: React.ReactNode;
  children: React.ReactNode;
  animate?: boolean;
};

const WindowContent = memo(
  ({ heading, intro, children, animate = false }: WindowContentProps) => {
    return (
      <motion.div
        className='container window__content'
        variants={MotionVariant.Fade}
        initial={animate ? 'initial' : false}
        animate={animate ? 'animate' : false}
      >
        {(heading || intro) && (
          <div className='window__head'>
            {heading && (
              <Heading className='window__heading'>{heading}</Heading>
            )}
            {intro}
          </div>
        )}
        <div className='window__body container__grow'>{children}</div>
      </motion.div>
    );
  }
);

export default WindowContent;
