import { memo, forwardRef } from 'react';
import classNames from 'classnames';

type HoverZoomFullProps = {
  src: string;
  children?: React.ReactNode;
  className?: string;
  zoom?: { width: number; height: number; top: number; left: number };
};

const HoverZoomFull = memo(
  forwardRef<HTMLDivElement, HoverZoomFullProps>(
    ({ src, zoom, children, className }, ref) => {
      return (
        <div ref={ref} className={classNames('hover-zoom__full', className)}>
          {children && (
            <div className='hover-zoom__full-placeholder'>{children}</div>
          )}
          {zoom && (
            <div
              className='hover-zoom__zoom'
              style={{
                backgroundImage: `url(${src})`,
                ...zoom,
              }}
            ></div>
          )}
        </div>
      );
    }
  )
);

export default HoverZoomFull;
