import { useState, useCallback } from 'react';
import { useSound, Sound } from '@lib/sfx';

const useDragItem = (
  combinationItem: CombinationItem,
  onDrag: (combinationItem: CombinationItem, offset: number) => void
) => {
  const [isDragging, setDragging] = useState(false);
  const [liftSfx] = useSound(Sound.Lift);
  const [snapSfx] = useSound(Sound.Snap);

  const handleDragStart = useCallback(() => {
    setDragging(true);
    liftSfx();
  }, [liftSfx]);
  const handleDragEnd = useCallback(() => {
    setDragging(false);
    snapSfx();
  }, [snapSfx]);

  const handleDragUpdate = useCallback(
    (_viewportBox, delta) => {
      if (isDragging) {
        onDrag(combinationItem, delta.x.translate);
      }
    },
    [isDragging, onDrag, combinationItem]
  );

  return [
    isDragging,
    handleDragStart,
    handleDragEnd,
    handleDragUpdate,
  ] as const;
};

export default useDragItem;
