import { forwardRef, memo } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import useDragItem from './useDragItem';

const onTop = { zIndex: 1 };
const flat = {
  zIndex: 0,
  transition: { delay: 0.3 },
};
const imgTop = { y: 0 };
const imgShifted = { y: 125 };

type DragItemProps = {
  combinationItem: CombinationItem;
  constraintsRef: React.RefObject<HTMLDivElement>;
  updateOrder: (combinationItem: CombinationItem, offset: number) => void;
  shiftImg?: boolean;
};

const DragItem = memo(
  forwardRef<HTMLDivElement, DragItemProps>(
    ({ combinationItem, constraintsRef, updateOrder, shiftImg }, ref) => {
      const [
        isDragging,
        handleDragStart,
        handleDragEnd,
        handleDragUpdate,
      ] = useDragItem(combinationItem, updateOrder);

      return (
        <motion.div
          ref={ref}
          className={classNames(
            'couch__item',
            `couch__item--${combinationItem}`,
            {
              'couch__item--dragging': isDragging,
            }
          )}
          initial={false}
          animate={isDragging ? onTop : flat}
          drag='x'
          layout
          dragConstraints={constraintsRef}
          dragElastic={0.1}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onViewportBoxUpdate={handleDragUpdate}
        >
          <motion.div
            className='couch__img'
            initial={shiftImg ? imgShifted : imgTop}
            animate={shiftImg ? imgShifted : imgTop}
            transition={{ ease: 'easeInOut' }}
          ></motion.div>
        </motion.div>
      );
    }
  )
);

export default DragItem;
