import { Variants } from 'framer-motion';

export const MotionVariant: {
  [key: string]: Variants;
} = {
  Fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  } as Variants,
  GrowShort: {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      pointerEvents: 'none',
      opacity: 1,
      scale: 1,
      transitionEnd: { pointerEvents: 'auto' },
    },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } },
  } as Variants,
  GrowSoft: {
    initial: {
      opacity: 0,
      scale: 0.7,
    },
    animate: {
      opacity: 1,
      scale: 1,
      pointerEvents: 'none',
      transition: { duration: 0.5 },
      transitionEnd: { pointerEvents: 'auto' },
    },
    exit: {
      opacity: 0,
      scale: 0.7,
      transition: { duration: 0.2 },
    },
  },
  FadeUp: {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } },
  } as Variants,
};
