import { memo } from 'react';
import Overlay, { OverlayVariant } from '@components/Overlay';
import { useGoToExplore, useCallout, Callout } from '@lib/state';
import Window from '@components/Window';

const Letter = memo(() => {
  const close = useGoToExplore();
  const [callout] = useCallout();

  const letter = (
    <div className='note'>
      <p>Lieber Gast,</p>
      <p>
        herzlich willkommen im BMS-Appartement!
        <br />
        Ich hoffe, Sie werden sich hier wohlfühlen.
      </p>
      <p>
        Ich möchte Sie bitten, meinen Apfelbaum zu gießen, falls Sie die Zeit
        dazu finden. Er steht in einem kleinen Gewächshaus auf dem Hof.
      </p>
      <p>
        Ungünstigerweise habe ich vor meiner Abreise den Schlüssel verlegt, er
        muss hier irgendwo im Raum sein.
        <br />
        Ich bin sicher, Sie werden ihn finden.
      </p>
      <p>Herzlichen Dank und eine gute Zeit!</p>
    </div>
  );

  if (callout === Callout.Letter) {
    return (
      <Window
        className='letter'
        heading='Sie haben eine Nachricht Ihrer Gastgeberin für Sie gefunden.'
      >
        <div className='center'>{letter}</div>
      </Window>
    );
  }

  return (
    <Overlay variant={OverlayVariant.Note} handleClose={close}>
      {letter}
    </Overlay>
  );
});

export default Letter;
