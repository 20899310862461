import { memo } from 'react';
import IconButton from '@components/IconButton';
import { Flyer } from '@components/Icon';
import { CalloutBubble } from '@components/Bubble';
import { Callout, useFound, View, useGoToView, ItemGroup } from '@lib/state';

const FlyerButton = memo(() => {
  const openFlyers = useGoToView(View.FlyerOverview);

  const found = useFound();
  const flyersFound = ItemGroup.Flyers.filter((flyer) => found.includes(flyer));

  return flyersFound.length > 0 ? (
    <CalloutBubble
      content={`Flyer ${flyersFound.length}/${ItemGroup.Flyers.length} wurde Ihrer Sammlung hinzugefügt.`}
      callout={Callout.Flyer}
      dismissInViews={View.Explore}
    >
      <IconButton
        Icon={Flyer}
        badge={`${flyersFound.length}/${ItemGroup.Flyers.length}`}
        onClick={openFlyers}
      />
    </CalloutBubble>
  ) : null;
});

export default FlyerButton;
