import { useUpdateEffect } from 'react-use';
import { useSound } from '@lib/sfx';
import {
  useFound,
  useAddCallout,
  useElapsedLoop,
  Item,
  ItemMeta,
  useUsed,
  useResetElapsedWithoutProgress,
  useRequestedHints,
} from '@lib/state';
import { View, Sound } from '@lib/entities';

const useStateEffects = () => {
  const found = useFound();
  const used = useUsed();
  const requestedHints = useRequestedHints();
  const addCallout = useAddCallout();
  const resetElapsedWithoutProgress = useResetElapsedWithoutProgress();
  const [successSfx] = useSound(Sound.Success);
  const [paperSfx] = useSound(Sound.Paper);
  const [lockedSfx] = useSound(Sound.Locked);
  const [unlockSfx] = useSound(Sound.Unlock);
  const [winSfx] = useSound(Sound.Win);

  // listen for changes in found array
  useUpdateEffect(() => {
    const item = found.length > 0 ? found[found.length - 1] : undefined;

    if (item) {
      // play sfx
      let sfx = ItemMeta?.[item]?.foundSfx;
      sfx = typeof sfx === 'function' ? sfx(found) : sfx;

      switch (sfx) {
        case Sound.Success:
          successSfx();
          break;

        case Sound.Paper:
          paperSfx();
          break;
      }

      // add callouts
      const callout = ItemMeta?.[item]?.foundCallout;
      if (callout) {
        addCallout(callout);
      }

      // reset need for help
      resetElapsedWithoutProgress();
    }
  }, [found]);

  // listen for changes in used array
  useUpdateEffect(() => {
    const item = used.length > 0 ? used[used.length - 1] : undefined;

    if (item) {
      // play sfx
      let sfx = ItemMeta?.[item]?.usedSfx;
      sfx = typeof sfx === 'function' ? sfx(used) : sfx;

      switch (sfx) {
        case Sound.Win:
          winSfx();
          break;
        case Sound.Unlock:
          unlockSfx();
          break;
        case Sound.Locked:
          lockedSfx();
          break;
      }

      // reset need for help
      resetElapsedWithoutProgress();
    }
  }, [used]);

  // listen for changes in requestedHints
  useUpdateEffect(() => {
    // reset need for help
    resetElapsedWithoutProgress();
  }, [requestedHints]);

  // run elapsed loop
  useElapsedLoop(
    [View.Loading, View.Welcome, View.Exit],
    used.includes(Item.GreenhouseDoor)
  );
};

export default useStateEffects;
